import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, Button, Divider, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdDeleteOutline } from "react-icons/md";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { object, string } from "yup";

import { useProject } from "../../../contexts/project.context";
import { CellService } from "../../../services/cell.service";
import UserService from "../../../services/user.service";
import { ProjectGroup } from "../../../types";
import { Group, User } from "../../../types/user.type";
import { Input } from "../../Input";
import TabContent from "../components/TabContent";

interface ProjectClientProps {
  onNext: () => void;
}

const emptyGroup: ProjectGroup = {
  group: {} as Group,
  leader: {} as User,
  firstCoordinator: {} as User,
  secondCoordinator: {} as User,
};

const schema = object({
  commercial: object({
    _id: string().required("Novos negócios - Comercial é obrigatório"),
    name: string(),
  }).required("Novos negócios - Comercial é obrigatório"),

  firstAttendance: object({
    _id: string().required("Atendimento 1 é obrigatório"),
    name: string(),
  }).required("Atendimento 1 é obrigatório"),
  secondAttendance: object({ _id: string(), name: string() }).nullable(),
});

const ProjectAssignTime = ({ onNext }: ProjectClientProps) => {
  const { assignTime, updateAssignTime, showNotification } = useProject();
  const [groups, setGroups] = useState<ProjectGroup[]>([emptyGroup]);

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      commercial: {},
      firstAttendance: {},
      secondAttendance: {},
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const commercialWatch = watch("commercial");
  const firstAttendanceWatch = watch("firstAttendance");
  const secondAttendanceWatch = watch("secondAttendance");

  const { data: groupsData, isLoading: groupsLoading } = useQuery(
    "groups",
    CellService.allCells
  );

  const { data: usersData, isLoading: usersLoading } = useQuery(
    "users",
    UserService.allUsers
  );

  function handleGroup(index: number, group?: ProjectGroup) {
    const newGroups = [...groups];
    newGroups[index] = group ?? ({} as ProjectGroup);
    setGroups(newGroups);
  }

  function handleGroupDelete(index: number) {
    const newRequestings = [...groups];
    newRequestings.splice(index, 1);
    setGroups(newRequestings);
  }

  function onSubmit(form: any) {
    const errorMgs = validateGroup1Members();
    if (errorMgs.length > 0) {
      for (const msg of errorMgs) {
        toast.error(msg);
      }
      return;
    }

    updateAssignTime({ ...form, groups });
    onNext();
  }
  interface Obj {
    [key: string]: any;
  }
  function validateGroup1Members() {
    const errorMsgs: Obj = {
      group: "Selecione uma célula",
      leader: "Selecione um Líder para a célula 1",
      // firstCoordinator: "Selecione um Coordenador para a célula 1"
    };
    const returnMessages = [];
    for (const [key, value] of Object.entries(groups[0])) {
      if (_.isEmpty(value) && !!errorMsgs[key]) {
        returnMessages.push(errorMsgs[key]);
      }
    }
    return returnMessages;
  }
  useEffect(() => {
    if (assignTime) {
      reset(assignTime);

      if (assignTime.groups.length > 0) {
        setGroups([...assignTime.groups]);
      }
    }
  }, []);
  useEffect(() => {
    if (errors) {
      for (const [key, value] of Object.entries(errors)) {
        if (value?._id?.message) toast.error(value?._id?.message);
      }
    }
  }, [errors]);
  return (
    <TabContent onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box fontSize={"16px"}>
          <strong>Atendimento</strong>
        </Box>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={2}
        >
          <Autocomplete
            options={usersData || []}
            getOptionLabel={(option) => option.name || ""}
            value={commercialWatch}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            onChange={(_, user) => {
              setValue("commercial", user), clearErrors("commercial");
            }}
            fullWidth
            loading={usersLoading}
            loadingText={"Carregando..."}
            renderInput={(params) => (
              <Input
                placeholder="Clique para selecionar"
                label="Novos negócios -  Comercial *"
                {...params}
              />
            )}
          />

          <Autocomplete
            fullWidth
            options={usersData || []}
            getOptionLabel={(option) => option.name || ""}
            value={firstAttendanceWatch}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            onChange={(_, user) => {
              setValue("firstAttendance", user), clearErrors("firstAttendance");
            }}
            loading={usersLoading}
            loadingText={"Carregando..."}
            renderInput={(params) => (
              <Input
                placeholder="Clique para selecionar"
                label="Atendimento 1 *"
                {...params}
              />
            )}
          />

          <Autocomplete
            fullWidth
            options={usersData || []}
            getOptionLabel={(option) => option.name || ""}
            value={secondAttendanceWatch}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            onChange={(_, user) => {
              setValue("secondAttendance", user),
                clearErrors("secondAttendance");
            }}
            loading={usersLoading}
            loadingText={"Carregando..."}
            renderInput={(params) => (
              <Input
                placeholder="Clique para selecionar"
                label="Atendimento 2"
                {...params}
              />
            )}
          />
        </Box>
      </Box>
      <Box fontSize={"16px"}>
        <strong>Células</strong>
      </Box>
      {groups.map((productGroup, index) => (
        <Box
          border={"1px solid #eee"}
          borderRadius={2}
          padding={1.5}
          key={index}
        >
          {index >= 0 && (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Typography variant="body2">Célula {index + 1}</Typography>
            </Box>
          )}
          <Box width={"33%"}>
            <Autocomplete
              fullWidth
              options={groupsData || []}
              getOptionLabel={(option) => option.name || ""}
              onChange={(e, group) => {
                if (group === null) {
                  return handleGroup(index, emptyGroup);
                }
                const leader = usersData?.find(
                  (el) => el._id === group?.leader_user_ids[0]
                );
                handleGroup(index, {
                  ...productGroup,
                  _id: group?._id ?? undefined,
                  group: group ?? ({} as Group),
                  leader: leader ?? ({} as User),
                  firstCoordinator: {} as User,
                  secondCoordinator: {} as User,
                });
              }}
              value={productGroup.group}
              isOptionEqualToValue={(option, value) =>
                option._id === value?._id
              }
              loading={groupsLoading}
              loadingText={"Carregando..."}
              renderInput={(params) => (
                <Input
                  placeholder="Clique para selecionar"
                  label={`Grupo`}
                  {...params}
                />
              )}
            />
          </Box>
          <Box
            gap={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Autocomplete
              options={
                usersData?.filter((user) =>
                  productGroup.group?.leader_user_ids?.includes(user._id)
                ) || []
              }
              getOptionLabel={(option) => option.name || ""}
              onChange={(e, user) =>
                handleGroup(index, {
                  ...productGroup,
                  leader: user ?? ({} as User),
                })
              }
              value={productGroup.leader}
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option._id === value?._id
              }
              loading={usersLoading}
              loadingText={"Carregando..."}
              disabled={!productGroup.group?._id}
              renderInput={(params) => (
                <Input
                  placeholder="Clique para selecionar"
                  label={`Líder *`}
                  {...params}
                />
              )}
            />

            <Autocomplete
              options={
                usersData?.filter((user) =>
                  productGroup.group?.coordinators_user_ids?.includes(user._id)
                ) || []
              }
              getOptionLabel={(option) => option.name || ""}
              onChange={(e, user) =>
                handleGroup(index, {
                  ...productGroup,
                  firstCoordinator: user ?? ({} as User),
                })
              }
              value={productGroup.firstCoordinator}
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option._id === value?._id
              }
              loading={usersLoading}
              loadingText={"Carregando..."}
              disabled={!productGroup.group?._id}
              renderInput={(params) => (
                <Input
                  placeholder="Clique para selecionar"
                  label={`1º Coordenador/Produtor (Responsável)  `}
                  {...params}
                />
              )}
            />

            <Autocomplete
              options={
                usersData?.filter((user) =>
                  productGroup.group?.coordinators_user_ids?.includes(user._id)
                ) || []
              }
              getOptionLabel={(option) => option.name || ""}
              onChange={(e, user) =>
                handleGroup(index, {
                  ...productGroup,
                  secondCoordinator: user ?? ({} as User),
                })
              }
              value={productGroup.secondCoordinator}
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option._id === value?._id
              }
              loading={usersLoading}
              loadingText={"Carregando..."}
              disabled={!productGroup.group?._id}
              renderInput={(params) => (
                <Input
                  placeholder="Clique para selecionar"
                  label={`2º Coordenador/Produtor (Responsável)`}
                  {...params}
                />
              )}
            />
          </Box>
          {index > 0 && (
            <Box display={"flex"} marginLeft={"auto"}>
              <Button
                variant="outlined"
                startIcon={<MdDeleteOutline />}
                color="error"
                onClick={() => handleGroupDelete(index)}
                sx={{ width: 150, mt: 1, ml: "auto" }}
              >
                Remover Célula
              </Button>
            </Box>
          )}
        </Box>
      ))}

      <Box display={"flex"} marginLeft={"auto"}>
        <Button
          variant="outlined"
          onClick={() => setGroups([...groups, emptyGroup])}
        >
          Adicionar célula
        </Button>
      </Box>
    </TabContent>
  );
};

export default ProjectAssignTime;
