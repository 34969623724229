import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "../../../../components/Input";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Switch } from "../../../UI/Switch";
import { FormInputMultiCheckbox } from "../../../novos/RSVP/PreCongress/FormMultipleCheckBox";
import styled from "styled-components";
import { createOptionsFromEnum } from "../../../../utils/formatters";
import {
  BillingEnum,
  PaymentMethodsEnum,
} from "../../../../types/aerial/enums";
import { RadioGroup } from "../../../UI/RadioGroup";
import { StyledBox } from "../../RSVP/styles";
import { SaveButton } from "../../../UI/SaveButton";
import { AuthContext } from "../../../../contexts/auth";
import { ProjectUploadFile } from "../../../UI/ProjectUploadFile";
import { useProject } from "../../../../contexts/project.context";
import YesOrNoSwitch from "../../../UI/YesOrNoSwitch";
import ToggleButtonGroup from "../../../ToggleButtonGroup";
import ColorToggleButton from "../../../ToggleButtonGroup";
import MultipleButtonToggle from "../../../ToggleButtonGroup";

type StateType = {
  routeNetWork: boolean;
  insurance: boolean;
  paymentMethod: string[];
  paymentMethodObs: string;
  billing: string[];
  billingObs: string;
  paymentSelected: string;
  billingSelected: string;
};

type PropTypes = {
  onSubmit(form: any): void;
  financialData: any;
  projectId: string;
};

const initialState: StateType = {
  routeNetWork: false,
  insurance: false,
  paymentMethod: [],
  paymentMethodObs: "",
  billing: [],
  billingObs: "",
  paymentSelected: "",
  billingSelected: "",
};
const FinancialForm = ({ onSubmit, financialData, projectId }: PropTypes) => {
  const { actionOptions } = useContext(AuthContext);

  const isDisabled =
    !actionOptions.createProjetos && !actionOptions.updateProjetos;
  const methods = useForm({
    defaultValues: initialState,
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const selectedPayment = watch("paymentSelected");
  const selectedBilling = watch("billingSelected");

  const paymentMethodOptions = createOptionsFromEnum(PaymentMethodsEnum);

  const billingOptions = createOptionsFromEnum(BillingEnum);
  const routeNetWorkWatch = watch("routeNetWork");
  const insuranceWatch = watch("insurance");
  const paymentMethodWatch = watch(
    "paymentMethod",
    financialData?.paymentMethod
  );
  const billingWatch = watch("billing", financialData?.billing);

  useEffect(() => {
    if (!!financialData) {
      reset(financialData);
      setValue("paymentSelected", financialData?.paymentMethod[0]);
      setValue("billingSelected", financialData?.billing[0]);
    }
  }, [financialData]);

  useEffect(() => {
    setValue("paymentMethod", [selectedPayment]);
  }, [selectedPayment]);

  useEffect(() => {
    setValue("billing", [selectedBilling]);
  }, [selectedBilling]);

  function handleUpload(files: File[]) {
    let reader = new FileReader();

    reader.readAsDataURL(files[0]);

    reader.onload = function () {
      let e = {
        target: {
          name: "image_url",
          value: reader.result,
        },
      } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    };

    reader.onerror = function () {};
  }

  const options = [
    { value: "money", label: "Dinheiro" },
    { value: "card", label: "Cartão" },
    { value: "billed", label: "Faturado" },
  ];

  return (
    <FormProvider {...methods}>
      <StyledBox onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: 8 }}>
        <Box sx={{ marginTop: 2 }}>
          <span style={{ fontSize: 20 }}>Financeiro</span>
        </Box>

        <Box display={"flex"} mt={2}>
          <Box
            sx={{
              display: "flex",
              width: "98%",
              flexDirection: "column",
              marginBottom: 2,
            }}
          >
            <Box flexDirection={"row"} display={"flex"} gap={4} my={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <span>Cotação malha aérea</span>
                <Box>
                  <YesOrNoSwitch
                    setValue={(type, newValue) =>
                      setValue("routeNetWork", newValue)
                    }
                    type="null"
                    value={routeNetWorkWatch}
                  />
                </Box>
              </Box>

              <Box flexDirection={"column"} display={"flex"} gap={1}>
                <span>Seguro em todos</span>
                <Box>
                  <YesOrNoSwitch
                    setValue={(type, newValue) =>
                      setValue("insurance", newValue)
                    }
                    type="null"
                    value={insuranceWatch}
                  />
                </Box>
              </Box>
            </Box>
            {/* <Switch
              valueLabel="Cotação malha aérea"
              label=""
              {...register("routeNetWork")}
              checked={routeNetWorkWatch}
            />

            <Switch
              valueLabel="Seguro em todos"
              label=""
              {...register("insurance")}
              checked={insuranceWatch}
            /> */}
            <Box
              sx={{
                border: "1px solid #E0E0E0",
                padding: 2,
                borderRadius: 1,
              }}
            >
              <FormGroup>
                <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                  <FormLabel style={{ color: "#222222" }}>
                    Forma de pagamento
                  </FormLabel>
                  <MultipleButtonToggle
                    name="paymentSelected"
                    options={paymentMethodOptions}
                    control={control}
                  />
                  {/* <ToggleButtonGroup
                  // options={["Dinheiro", "Cartão", "Faturado"]}
                  // value={selected}
                  // onChange={setSelected}
                  /> */}
                </Box>

                {/* <FormInputMultiCheckbox
                  name={"paymentMethod"}
                  control={control}
                  label={"label"}
                  options={paymentMethodOptions}
                  setValue={setValue}
                  previousSelected={paymentMethodWatch} // adicionar quando integrar com o backend
                  optionSelectAll={false}
                /> */}
              </FormGroup>
              <Input
                label="Descrição"
                placeholder="Descreva as formas de pagamento"
                {...register("paymentMethodObs")}
              />
            </Box>

            <Box
              sx={{
                border: "1px solid #E0E0E0",
                padding: 2,
                borderRadius: 1,
                marginTop: 2,
              }}
            >
              <FormGroup>
                <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                  <FormLabel style={{ color: "#222222" }}>
                    Faturamento
                  </FormLabel>
                  <MultipleButtonToggle
                    name="billingSelected"
                    options={billingOptions}
                    control={control}
                  />
                  {/* <ToggleButtonGroup
                  // options={["Dinheiro", "Cartão", "Faturado"]}
                  // value={selected}
                  // onChange={setSelected}
                  /> */}
                </Box>
                {/* <FormLabel id="demo-radio-buttons-group-label">
                  Faturamento
                </FormLabel>
                <FormInputMultiCheckbox
                  name={"billing"}
                  control={control}
                  label={"label"}
                  options={billingOptions}
                  setValue={setValue}
                  previousSelected={billingWatch}
                  optionSelectAll={false}
                /> */}
              </FormGroup>
              <Input
                label="Observação"
                placeholder="Insira mais detalhes"
                {...register("billingObs")}
              />
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              width: "40%",
              margin: "0 auto",
              flexDirection: "column",
            }}
          >
            <ProjectUploadFile projectId={projectId} area={"air"}/>
          </Box> */}
        </Box>
        <SaveButton isDisabled={isDisabled} />
      </StyledBox>
    </FormProvider>
  );
};

export default FinancialForm;
