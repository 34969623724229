import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { BudgetService } from "../../services/budget.service";
import {
  AllTotals,
  Budget,
  BudgetItemTypeEnum,
  ByItemType,
  HeaderOptions,
  ItemTypeListPerCalcOption,
  versionInfo,
  VersionTotals,
} from "../../types/budget/budget";
import { ServiceData } from "../../types/budget/service-item";
import { ActiveBudgetAction, activeBudgetReducer } from "./reducer-funcion";
import { toast } from "react-toastify";
import { useProject } from "../project.context";

export type BudgetContextProps = {
  notification?: any;
  loadingBudget?: boolean;
  isLoadingCalcs: boolean;
  projectId?: string;
  budgetList?: Budget[];
  activeBudget: Budget;
  activeBudgetIndex: number;
  negotiationVersion: number;
  activeCalcOption: HeaderOptions;
  hasNegotiations: boolean;
  hasClosings: boolean;
  detailedBudget: string;
  versionInfo: versionInfo;
  createBudget: (budgetData: Budget) => void;
  updateBudget: (
    budgetId: string,
    budgetData: Budget,
    action: string,
    version: number
  ) => void;
  loadBudgetList: (projectId: string) => void;
  closeNotification: () => void;
  showNotification: (message: string, severity: string) => void;
  clearBudget: () => void;
  createEmptyBudget: () => void;
  setIsLoadingCalcs: (value: boolean) => void;
  setLoadingBudget: (value: boolean) => void;
  activeBudgetDispatch: Dispatch<ActiveBudgetAction>;
  setNegotiationVersion: (version: number) => void;
  setActiveCalcOption: (option: HeaderOptions) => void;
  setDetailedBudget: (id: string) => void;
  setVersionInfo: (versionInfo: versionInfo) => void;
};

const BudgetContext = createContext<BudgetContextProps>(
  {} as BudgetContextProps
);

function BudgetProvider({ children }: any) {
  const { setTabActive, tabActive } = useProject();
  const [detailedBudget, setDetailedBudget] = useState("");
  const [versionInfo, setVersionInfo] = useState<versionInfo>({
    comparisonVersion: 0,
    version: 0,
    status: "OPEN",
  });
  const [activeBudget, activeBudgetDispatch] = useReducer(
    activeBudgetReducer,
    {} as Budget
  );

  type budgetListAction = {
    type: "UPDATE_LIST";
    payload: Budget[];
  };

  function budgetListReducer(state: Budget[], action: budgetListAction) {
    return action.payload;
  }

  const [budgetList, budgetListRDispatch] = useReducer(
    budgetListReducer,
    [] as Budget[]
  );
  const [negotiationVersion, setNegotiationVersion] = useState(-1);
  const [hasNegotiations, setHasNegotiations] = useState(false);
  const [hasClosings, setHasClosings] = useState(false);
  const [activeCalcOption, setActiveCalcOption] = useState<HeaderOptions>("A");
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [isLoadingCalcs, setIsLoadingCalcs] = useState(false);

  const [activeBudgetIndex, setActiveBudgetIndex] = useState<number>(0);
  const [projectId, setProjectId] = useState<string>();
  const [notification, setNotification] = useState<any>({
    open: false,
    message: "",
    severity: "success",
  });

  useMemo(() => {
    async function updateBudget() {
      setIsLoadingCalcs(true);
      try {
        if (activeBudget._id) {
          const updatedFromApi = await BudgetService.update(
            activeBudget._id,
            activeBudget
          );
          const newBudgetList = JSON.parse(JSON.stringify(budgetList));
          const newActiveBudgetIndex = budgetList.findIndex(
            (el) => el._id === activeBudget._id
          );
          newBudgetList[newActiveBudgetIndex] = updatedFromApi;
          budgetListRDispatch({ type: "UPDATE_LIST", payload: newBudgetList });

          if (!!newBudgetList.find((el: any) => el.status === "NEGOTIATE"))
            setHasNegotiations(true);
          if (
            !!newBudgetList.find(
              (el: any) =>
                !!el.versionHistory.find(
                  (version: any) => version.status === "APROVED"
                )
            )
          )
            setHasClosings(true);

          activeBudgetDispatch({
            type: "UPDATE_FROM_API",
            payload: { ...updatedFromApi, isApiUpdate: true },
          });
        }
      } catch (error) {
        toast.warn("Houve um problema ao salvar as alterações");
      }
      setIsLoadingCalcs(false);
    }
    if (activeBudget?._id && !activeBudget?.isApiUpdate) {
      updateBudget();
    }
  }, [activeBudget]);

  async function createEmptyBudget() {
    if (!!projectId)
      await createBudget({
        project: projectId,
        status: "OPEN",
        budgetServices: [],
        paymentSolicitation: [],
        itemTypeList: {} as ItemTypeListPerCalcOption,
        supplierList: [],
        addedItemTypeList: [],
        budgetValuesTotal: {
          byCalculationType: {} as AllTotals,
          byItemType: {} as ByItemType,
        },
        negociationValuesTotal: [] as VersionTotals[],
        closingValuesTotal: {} as VersionTotals,
        negotiationVersion: 0,
        files: [],
        versionHistory: [{ status: "OPEN", version: 0, comparisonVersion: 0 }],
        budgetIndex: 0,
      });
  }

  async function updateBudget(
    budgetId: string,
    budgetData: Budget,
    action: string,
    version: number
  ) {
    try {
      const newBudgetList = budgetList.map((budget) => {
        if (budget._id === budgetData._id) return budgetData;
        return budget;
      });
      budgetListRDispatch({ payload: newBudgetList, type: "UPDATE_LIST" });
      const versInfo = budgetData.versionHistory.find(el => el.version === version)
      //check negotiations
      const negotitations = budgetList.find(
        (el) =>
          !!el.versionHistory.find((ver: any) => ver.status === "NEGOTIATE") &&
          el.status !== "DELETED"
      );
      if (!negotitations) {
        setHasNegotiations(false);
      }

      const closing = budgetList.find(
        (el) =>
          !!el.versionHistory.find((ver: any) => ver.status === "APROVED") &&
          el.status !== "DELETED"
      );
      if (!closing) {
        setHasClosings(false);
      }
      if (budgetId) {
        if (action === "DELETED") {
          activeBudgetDispatch({
            type: "NO_ACTIVE_BUDGET",
            payload: undefined,
          });
          setDetailedBudget("");
          setVersionInfo({
            comparisonVersion: 0,
            version: 0,
            status: "",
          });

          if (tabActive === 10) {
            setHasClosings(false);
            setTabActive(8);
          }

          return await BudgetService.update(budgetId, budgetData);
        }
        if (action === "DENIED") {
          const openNegotiations = budgetData.versionHistory.filter((el) => {
            return el.status === "NEGOTIATE";
          });
          if (openNegotiations.length === 0) {
            budgetData.versionHistory[0].status === "OPEN"
              ? setDetailedBudget(`${budgetData?._id}-version-0`)
              : setDetailedBudget("");
            setTabActive(8);
            setHasNegotiations(false);
            setVersionInfo({
              comparisonVersion: 0,
              version: 0,
              status: "",
            });
          } else {
            setDetailedBudget(
              `${budgetData?._id}-version-${
                openNegotiations[0].version ? openNegotiations[0].version : 0
              }`
            );
            setVersionInfo(openNegotiations[0]);
          }

          return await BudgetService.update(budgetId, budgetData);
        }

        if (action === "APROVED") {
          setTabActive(10);
          setNegotiationVersion(budgetData.negotiationVersion);
          setDetailedBudget(
            `${budgetData?._id}-version-${version ? version : 0}`
          );
        }
        if (action === "NEGOTIATE") {
          setTabActive(9);
          setNegotiationVersion(budgetData.negotiationVersion);
          setDetailedBudget(budgetData?._id + "-version-" + version);
          setHasNegotiations(true);
        }
        if (action === "NEW_VERSION") {
          setDetailedBudget(budgetData?._id + "-version-" + version);
        }
        if(versInfo) setVersionInfo(versInfo)
        activeBudgetDispatch({
          type: "NEW_ACTIVE_BUDGET",
          payload: budgetData,
        });
      }
    } catch (error) {
      showNotification(`Não foi possível atualizar o orçamento`, "warning");
    }
  }

  function showNotification(message: string, severity = "success") {
    setNotification({ open: true, message, severity });
  }

  function closeNotification() {
    setNotification({ ...notification, open: false });
  }

  async function loadBudgetList(projectId: string) {
    setLoadingBudget(true);

    try {
      setProjectId(projectId);
      const budgetList = await BudgetService.getBudgetList(projectId);
      if (budgetList.length > 0) {
        const activeBudgets = budgetList.filter(
          (el: any) => el.status !== "DELETED"
        );
        const negotiations = budgetList.find(
          (el: any) =>
            !!el.versionHistory.find((ver: any) => ver.status === "NEGOTIATE")
        );

        const closing = budgetList.find(
          (el: any) =>
            !!el.versionHistory.find((ver: any) => ver.status === "APROVED")
        );
        setHasClosings(!!closing);
        setHasNegotiations(!!negotiations);
        if (activeBudgets.length > 0)
          budgetListRDispatch({ type: "UPDATE_LIST", payload: activeBudgets });
      } else {
        budgetListRDispatch({ type: "UPDATE_LIST", payload: [] });
        activeBudgetDispatch({ payload: undefined, type: "NO_ACTIVE_BUDGET" });
        setHasClosings(false);
        setHasNegotiations(false);
      }
    } catch (error) {
      showNotification("Não foi possível carregar", "warning");
    }
    setLoadingBudget(false);
  }

  async function createBudget(data: Budget) {
    try {
      const budgetData = await BudgetService.create(data);
      setDetailedBudget(budgetData._id);
      showNotification("Criado com Sucesso!", "success");
      budgetListRDispatch({
        type: "UPDATE_LIST",
        payload: [...budgetList, budgetData],
      });
      activeBudgetDispatch({ payload: budgetData, type: "NEW_ACTIVE_BUDGET" });
    } catch (error) {
      showNotification("Não foi possível criar", "warning");
    }
  }

  function clearBudget() {
    activeBudgetDispatch({ payload: undefined, type: "NO_ACTIVE_BUDGET" });
    setProjectId(undefined);
  }

  return (
    <BudgetContext.Provider
      value={{
        isLoadingCalcs,
        notification,
        loadingBudget,
        projectId,
        budgetList,
        activeBudget,
        negotiationVersion,
        activeCalcOption,
        activeBudgetIndex,
        hasClosings,
        hasNegotiations,
        detailedBudget,
        versionInfo,
        setDetailedBudget,
        setActiveCalcOption,
        setNegotiationVersion,
        createBudget,
        updateBudget,
        loadBudgetList,
        closeNotification,
        showNotification,
        clearBudget,
        createEmptyBudget,
        setIsLoadingCalcs,
        activeBudgetDispatch,
        setLoadingBudget,
        setVersionInfo,
      }}
    >
      {children}
    </BudgetContext.Provider>
  );
}

const useBudget = () => useContext(BudgetContext);
export { BudgetProvider, useBudget };
