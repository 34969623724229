import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AerialServiceItem } from "../AerialServiceItem";
import { SaveButton } from "../../../UI/SaveButton";
import { AuthContext } from "../../../../contexts/auth";
import { useQuery, useQueryClient } from "react-query";
import AerialService from "../../../../services/aerial.service";

type PropTypes = {
  ticketsData: any[];
  aerialId: string;
  project: string;
  onSubmit(form: any): void;
};

const AerialAccordion: React.FC<PropTypes> = ({
  ticketsData,
  aerialId,
  project,
  onSubmit,
}: PropTypes) => {
  const { actionOptions } = useContext(AuthContext);
  const [savedData, setSavedData] = useState<any[]>(ticketsData || []);
  const [expanded, setExpanded] = useState(-1);
  const [deleteDialog, setDeleteDialog] = useState<any>(null);

  const { refetch } = useQuery({
    queryKey: [`creative-data`],
    queryFn: () => AerialService.get(project),
    onSuccess: (data) => {
      setSavedData(data?.ticketsData);
    },
    enabled: !!project,
  });

  function deleteAerialService(serviceIndex: number) {
    const newArr = [...savedData];
    newArr.splice(serviceIndex, 1);
    setSavedData(newArr);
  }
  function updateAerialService(form: any, index: number) {
    const newSavedData = savedData;
    newSavedData[index] = form;
    setSavedData(newSavedData);
  }
  function handleAddEmptyService() {
    setSavedData([
      ...savedData,
      {
        outwardQuantity: 0,
        returnQuantity: 0,
        outwardDate: "",
        returnDate: "",
        aerialMesh: false,
        outwardAirport: "",
        returnAirport: "",
        airportConnections: [],
        airportPreference: false,
        flightClass: [],
        insurance: false,
      },
    ]);
    setExpanded(-1);
  }
  function handleChange(index: number, state: boolean) {
    if (index === expanded) return setExpanded(-1);
    setExpanded(index);
  }

  function handleDeleteDialog(index: number) {
    setDeleteDialog(index);
  }

  function handleConfirmDelete() {
    try {
      const newData = [...savedData];
      newData.splice(deleteDialog, 1);
      setDeleteDialog(null);
      setSavedData(newData);
      onSubmit({ _id: aerialId, ticketsData: newData, project });
      setTimeout(() => {
        refetch();
      }, 1000);
    } catch (e) {
      console.log("error", e);
    }
  }
  // useEffect(()=> {
  //   if(!!ticketsData) setSavedData(ticketsData)
  // },[ticketsData])
  function renderItems(data: any[]) {
    return data.map((service: any, index: any) => {
      return (
        <Box key={index} marginTop={2}>
          <AerialServiceItem
            handleDeleteService={deleteAerialService}
            handleSaveService={updateAerialService}
            data={service}
            expanded={expanded === index}
            onChange={handleChange}
            title={`Aéreo ${index + 1}`}
            index={index}
            handleDeleteDialog={handleDeleteDialog}
          />
        </Box>
      );
    });
  }
  return (
    <Box
      sx={{
        maxHeight: "65vh",
        minHeight: "500px",
        overflow: "auto",
        padding: "10px",
        width: "90%",
        overflowX: "hidden",
      }}
    >
      <Dialog open={deleteDialog !== null}>
        <DialogTitle>
          Deseja realmente deletar o Aéreo {deleteDialog + 1}?
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="error"
              sx={{ width: "48%", marginRight: 1 }}
              onClick={handleConfirmDelete}
            >
              <span>Deletar</span>
            </Button>
            <Button
              color="primary"
              sx={{ width: "48%" }}
              onClick={() => setDeleteDialog(null)}
            >
              <span>Cancelar</span>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <span style={{ fontSize: 20 }}>Passagens</span>
      </Box>
      {renderItems(savedData)}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          marginTop: 2,
          gap: 2,
        }}
      >
        <Button variant="outlined">
          <span style={{}} onClick={handleAddEmptyService}>
            Adicionar aéreo
          </span>
        </Button>

        {savedData.length > 0 && (
          <div
            onClick={() =>
              onSubmit({ _id: aerialId, ticketsData: savedData, project })
            }
          >
            <Button disabled={!actionOptions.updateProjetos} type="submit">
              <span>Salvar e avançar</span>
            </Button>
          </div>
        )}
      </Box>
    </Box>
  );
};

export { AerialAccordion };
