import {
  Autocomplete,
  AutocompleteProps,
  Box,
  InputLabel,
  TextField,
} from "@mui/material";

interface CustomAutoCompleteProps
  extends AutocompleteProps<
    any,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  > {
  label: string;
  inputValue: string;
}

export function LabeledAutoComplete(props: CustomAutoCompleteProps) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      width={"100%"}
    >
      <InputLabel style={{ fontSize: "13px" }}>{props.label}</InputLabel>
      <Autocomplete
        disablePortal
        size="small"
        {...props}
        sx={{ width: "100%" }}
      />
    </Box>
  );
}
