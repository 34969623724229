import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef, GridSortDirection, GridSortItem } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { Column } from "react-table";
import { Type } from "typescript";

import TableComponent from "../../components/CustomDataGrid";
import { AuthContext } from "../../contexts/auth";
import { useProject } from "../../contexts/project.context";
import { api } from "../../services/apiClient";
import ProjectService from "../../services/project.service";
import UserService from "../../services/user.service";
import { ProjectGroup } from "../../types";
import { BRIEFING_STATUS_OPTIONS } from "../../utils/briefingStatus";
import { IProjectRow } from "./project-row";

export default function Projects() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status") || "all";
  const { clearProject } = useProject();

  const INITIAL_STATE = {
    page: 0,
    pageSize: 20,
    order: "desc" as GridSortDirection,
    sortField: "mmCode",
    search: "",
    status,
    userId: "all",
  };
  const { actionOptions } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [projectData, setProjectData] = useState(INITIAL_STATE);
  const [searchTerm, setSearchTerm] = useState("");

  const { data: usersData } = useQuery({
    queryKey: ["users-data"],
    queryFn: () => UserService.allUsers(),
  });

  const { isLoading, data, error } = useQuery({
    queryKey: ["projects", projectData],
    queryFn: () => {
      return ProjectService.search(projectData);
    },
  });

  const columns: Column<IProjectRow>[] = [
    {
      Filter: "",
      accessor: "mmCode",
      Header: "Número MM",
      Cell: ({ cell }) => <span> {`${cell.value}/${cell.row.original.yearCode}`} </span>,
      width: 90,
      disableSortBy: true,
    },
    {
      id: "eventName",
      Filter: "",
      accessor: "event",
      Header: "Nome do evento",
      Cell: ({ cell }) => <span> {`${cell.value?.name || "---"} `} </span>,
      width: 110,
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "client",
      Header: "Nome da empresa",
      Cell: ({ cell }) => <span> {`${cell.value?.name || "---"} `} </span>,
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "firstAttendance",
      Header: "Atendimento",
      Cell: ({ cell }) => <span> {`${cell.value?.name || "---"} `} </span>,
      width: 80,
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "groupsInfo",
      Header: "Coord. Prod.1",
      width: 90,
      Cell: ({ cell }) => {
        return <span>{cell.value[0]?.firstCoordinator?.name || "---"}</span>;
      },
      disableSortBy: true,
    },
    {
      Filter: "",
      id: "leader",
      accessor: "groupsInfo",
      Header: "Líder",
      width: 90,
      Cell: ({ cell }) => {
        return <span>{cell.value[0]?.leader?.name || "---"}</span>;
      },
      disableSortBy: true,
    },
    {
      id: "eventDate",
      Filter: "",
      accessor: "event",
      Header: "Início do Evento",
      width: 120,
      Cell: ({ cell }) => {
        return (
          <span>
            {cell.value?.startDate ? format(new Date(cell.value?.startDate), "dd/MM/yyyy") : "---"}
          </span>
        );
      },
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "status",
      width: 70,
      Header: "Status",
      Cell: ({ cell }) => (
        <span>
          {cell.value
            ? BRIEFING_STATUS_OPTIONS.find(({ value }) => value === cell.value?.status)?.text
            : "A definir"}
        </span>
      ),
      disableSortBy: true,
    },
    {
      id: "eventType",
      accessor: "event",
      Filter: "",
      Header: "Tipo do evento",
      width: 120,
      Cell: ({ cell }) => cell.value?.type || "---",
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "_id",
      Header: "Ações",
      width: 50,
      Cell: ({ cell }) => (
        <Button
          variant="outlined"
          disabled={!actionOptions.viewProjetos}
          onClick={() => history.push(`/projeto?projectId=${cell.value}`)}
        >
          Abrir
        </Button>
      ),
      disableSortBy: true,
    },
  ];
  const updatePage = (pageNumber: number) => {
    setProjectData({ ...projectData, page: pageNumber });
  };
  const updateRowsPerPage = (limit: number) => {
    setProjectData({ ...projectData, pageSize: limit, page: 0 });
  };
  useEffect(() => {
    const delayOnSearch = setTimeout(async () => {
      setProjectData({ ...projectData, search: searchTerm });
    }, 1000);
    return () => clearTimeout(delayOnSearch);
  }, [searchTerm]);

  clearProject();
  return (
    <Box display={"flex"} flexDirection={"column"} flex={1} py={4} px={3} bgcolor={"#F9FCFF"}>
      <Card
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          mt: 4,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          overflow: "auto",
        }}
      >
        <Box display={"flex"} mb={4} alignItems={"center"} flex={1}>
          <Box display={"flex"} alignItems={"center"} flex={1} gap={2}>
            <TextField
              size="small"
              placeholder="Pesquisar por MM, nome do evento, nome da empresa, atendimento, coordenador ou líder da célula"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ width: "50%" }}
            />

            <FormControl sx={{ width: "20%" }}>
              <InputLabel id="filter-status">Status</InputLabel>
              <Select
                labelId="filter-status"
                label="Status"
                size="small"
                value={projectData.status}
                onChange={(e) => setProjectData({ ...projectData, status: e.target.value })}
              >
                <MenuItem value={"all"}>Todos</MenuItem>

                {BRIEFING_STATUS_OPTIONS.map(({ value, text }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: "20%" }}>
              <InputLabel size="small" id="filter-user">
                Usuário
              </InputLabel>
              <Select
                labelId="filter-status"
                label="Usuário"
                size="small"
                value={projectData.userId}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    userId: e.target.value,
                    page: 0,
                    pageSize: 20,
                  })
                }
              >
                <MenuItem value={"all"}>Todos</MenuItem>

                {usersData
                  ?.filter((user: any) => user.active)
                  .map((user: any) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            variant="contained"
            disabled={!actionOptions.createProjetos}
            onClick={() => {
              history.push(`/projeto`);
              clearProject();
            }}
          >
            Novo Projeto
          </Button>
        </Box>
        <TableComponent
          columns={columns}
          data={data?.entry || []}
          isLoading={isLoading}
          givenPage={projectData.page}
          rowsPerPage={projectData.pageSize}
          total={data?.total || 0}
          onPageChange={updatePage}
          onRowsPerPageChange={updateRowsPerPage}
        />
      </Card>
    </Box>
  );
}
