import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthContext } from "../../contexts/auth";
import ClientService from "../../services/client.service";
import { SEGMENT_OPTIONS } from "../../utils/customerSegments";
import RightSideBar from "../novos/Customers/RightSideBar";
import { LabeledInput } from "../UI/LabeledInput";
import SelectForm from "../UI/Select";
import YesOrNoSwitch from "../UI/YesOrNoSwitch";
import { maskCPFOrCNPJ } from "../../utils/formatters";

type Props = {
  edit: any;
};
export function Customer({ edit }: Props) {
  const { actionOptions } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const customerId = location.search.split("=");

  const { data: clientData, isLoading: clientLoading } = useQuery({
    queryKey: ["client"],
    queryFn: () => ClientService.getClients({ id: customerId[1] }),
  });

  const [state, setState] = useState({} as any);
  const [taxId, setTaxId] = useState("");

  useEffect(() => {
    if (!!clientData) {
      setState(clientData);

      const formData: any = clientData;

      if (formData?.tax_id) {
        const formattedValue = maskCPFOrCNPJ(formData?.tax_id);
        setTaxId(formattedValue);
      }
    }
  }, [clientData]);

  //   {
  //   _id: edit?._id || undefined,
  //   handle_id: edit?.handle_id || null, // como saber qual handle id passar?
  //   name: edit?.name || "",
  //   nickname: edit?.nickname || "",
  //   segment: edit?.segment || "",
  //   email: edit?.email || "",
  //   tax_id: edit?.tax_id || "",
  //   country: edit?.country || "",
  //   state: edit?.state || "",
  //   city: edit?.city || "",
  //   neighborhood: edit?.neighborhood || "",
  //   address: edit?.address || "",
  //   number: edit?.number || "",
  //   zip_code: edit?.zip_code || "",
  //   phone: edit?.phone || "",
  //   street_type: edit?.street_type || "",
  //   complement: edit?.complement || "",
  //   latitude: edit?.latitude || 0,
  //   longitude: edit?.longitude || 0,
  //   origin: edit?.origin || "",
  //   business_group: edit?.business_group || "",
  //   business_group_name: edit?.business_group_name || "",
  //   custom_fields: edit?.custom_fields || "",
  //   companies: edit?.companies || {},
  //   contracts: edit?.contracts || "",
  //   agency_contract: edit?.agency_contract || true,
  //   events_contract: edit?.events_contract || true,
  //   has_supplier: edit?.has_supplier || false,
  //   collaborator_mm: edit?.collaborator_mm || true,
  //   physical_person: edit?.physical_person || false,
  //   juridical_person: edit?.juridical_person || false,
  //   national: edit?.national || false,
  //   international: edit?.international || false,
  //   cnpj_cpf: edit?.cnpj_cpf || "",
  //   client_requester: edit?.client_requester || "",
  //   external_client: edit?.external_client || true,
  // });
  const { mutate: fetchCnpjData, isLoading: isLoadingFetchCnpj } = useMutation(
    ClientService.fetchCnpjData,
    {
      onSuccess: (apiData) => {
        if (apiData) {
          setState((prevState: any) => ({
            ...prevState,
            zip_code: apiData.CEP || "",
            country: "Brasil",
            state: apiData.UF || "",
            city: apiData.MUNICIPIO || "",
            neighborhood: apiData.BAIRRO || "",
            street_type: apiData.LOGRADOURO || "",
            email: apiData.EMAIL || "",
            number: apiData.NUMERO || "",
            complement: apiData.COMPLEMENTO || "",
          }));
        }
      },
      onError: (error) => {
        console.error("Error fetching CNPJ data", error);
      },
    }
  );

  const { mutate: createClient, isLoading: isLoadingCreate } = useMutation(
    ClientService.create,
    {
      onSuccess: () => {
        toast.success("Client criado com sucesso!");
        history.push("/partners/customers");
      },
      onError: (error) => {
        toast.error("Não foi possível criar o cliente");
      },
    }
  );

  const { mutate: updateClient, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => ClientService.update(state._id, data),
    {
      onSuccess: () => {
        toast.success("Cliente atualizado com sucesso!");
        history.push("/partners/customers");
      },
      onError: (error) => {
        toast.error("Não foi possível atualizar o cliente");
      },
    }
  );
  const handleSubmmit = (e: React.MouseEvent) => {
    const data = {
      ...state,
    };
    delete data._id;
    delete data.updated_at;
    state._id ? updateClient(data) : createClient({ ...data, origin: "MMIDD" });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === "number") {
      const numberRegex = new RegExp("^[0-9]*$");
      if (!numberRegex.test(e.target.value)) {
        e.target.value = e.target.value.replace(/\D/g, "");
      }
    }

    if (e.target.name === "tax_id") {
      const isCPF = e.target.value.length <= 14;

      const taxState = {
        physical_person: isCPF,
        juridical_person: !isCPF,
      };

      const formattedValue = maskCPFOrCNPJ(e.target.value);
      setTaxId(formattedValue);

      setState({
        ...state,
        ...taxState,
        [e.target.name]: e.target.value,
      });

      return;
    }
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTaxIdBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const unmaskedCnpj = value.replace(/\D/g, "");
    fetchCnpjData(unmaskedCnpj);
  };
  const isDisabled =
    !actionOptions.updateParceiros || !actionOptions.createParceiros;

  const getTitle = () => {
    if (state._id) return `${state?.handle_id} - ${state?.name}`;
    else return "Criar cliente";
  };

  return (
    <Box
      display={"flex"}
      width={"100%"}
      columnGap={2}
      py={1}
      flexDirection={"column"}
    >
      <span style={{ fontSize: 20, marginBottom: 8 }}>{getTitle()}</span>
      <Box display={"flex"} width={"100%"} columnGap={2} py={1}>
        <Box
          style={{
            padding: "20px 20px",
            boxShadow: "0px 0px 2px 1px #C4C4C4",
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box marginBottom={2}>
            <span style={{ fontWeight: "bold", fontSize: 16 }}>
              Informações básicas
            </span>
          </Box>

          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Box display={"flex"} gap={4}>
              <FormControl>
                <FormLabel>Fornecedor</FormLabel>
                <YesOrNoSwitch
                  setValue={(_, value) => {
                    if (value !== null)
                      setState({
                        ...state,
                        has_supplier: value,
                      });
                  }}
                  value={!!state.has_supplier}
                  type=""
                  align="flex-start"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Colaborador MM</FormLabel>

                <YesOrNoSwitch
                  setValue={(_, value) => {
                    if (value !== null)
                      setState({
                        ...state,
                        collaborator_mm: value,
                      });
                  }}
                  value={!!state.collaborator_mm}
                  type=""
                  align="flex-start"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Internacional</FormLabel>

                <YesOrNoSwitch
                  setValue={(_, value) => {
                    if (value !== null)
                      setState({
                        ...state,
                        international: value,
                        national: !value,
                      });
                  }}
                  value={!!state.international}
                  type=""
                  align="flex-start"
                />
              </FormControl>
            </Box>

            <FormControl style={{ width: "30%", marginTop: 16 }}>
              <SelectForm
                label="Segmento do Cliente"
                value={state.segment}
                onChange={(e) =>
                  setState({ ...state, segment: e.target.value })
                }
              >
                {SEGMENT_OPTIONS.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectForm>
            </FormControl>

            <LabeledInput
              size="small"
              label={"CPF/CNPJ"}
              name="tax_id"
              value={taxId}
              onChange={handleChange}
              onBlur={handleTaxIdBlur}
              placeholder={
                state.physical_person ? "000.000.000-00" : "00.000.000/0000-00"
              }
            />
            {/* <LabeledInput
              size="small"
              label="Solicitante do Cliente"
              name="client_requester"
              value={state.client_requester}
              onChange={handleChange}
            /> */}
            <LabeledInput
              size="small"
              label="Nome"
              name="name"
              value={state.name}
              onChange={handleChange}
            />
            <LabeledInput
              size="small"
              label="Nome de uso/apelido"
              name="name"
              value={state.name}
              onChange={handleChange}
            />
            <LabeledInput
              size="small"
              label="Nome Grupo"
              name="business_group_name"
              value={state.business_group_name}
              onChange={handleChange}
            />

            <Box
              style={{ height: 1, width: "100%", backgroundColor: "#E0E0E0" }}
              marginBottom={3}
              marginTop={3}
            />

            <Box marginBottom={2}>
              <span style={{ fontWeight: "bold", fontSize: 16 }}>Contato</span>
            </Box>

            <LabeledInput
              size="small"
              fullWidth
              label="E-mail"
              name="email"
              value={state.email}
              onChange={handleChange}
            />
            <ReactInputMask
              // @ts-ignore
              maskPlaceholder={null}
              mask="(99) 99999-9999"
              value={state.phone}
              onChange={handleChange}
              name="phone"
            >
              <LabeledInput
                size="small"
                label="Telefone"
                name="phone"
                value={state.phone}
                onChange={handleChange}
                placeholder="(00) 0 0000 0000"
              />
            </ReactInputMask>

            <Box
              style={{ height: 1, width: "100%", backgroundColor: "#E0E0E0" }}
              marginBottom={3}
              marginTop={3}
            />

            <Box marginBottom={2}>
              <span style={{ fontWeight: "bold", fontSize: 16 }}>Endereço</span>
            </Box>

            <ReactInputMask
              // @ts-ignore
              maskPlaceholder={null}
              mask="99999-999"
              value={state.zip_code}
              onChange={handleChange}
              name="zip_code"
            >
              <LabeledInput
                size="small"
                label="CEP"
                name="zip_code"
                value={state.zip_code}
                onChange={handleChange}
                placeholder="00000-000"
              />
            </ReactInputMask>
            <LabeledInput
              size="small"
              fullWidth
              label="País"
              value={state.country}
              name="country"
              onChange={handleChange}
            />

            <LabeledInput
              size="small"
              fullWidth
              label="Estado"
              value={state.state}
              name="state"
              onChange={handleChange}
            />

            <LabeledInput
              size="small"
              fullWidth
              label="Município"
              value={state.city}
              name="city"
              onChange={handleChange}
            />

            <LabeledInput
              size="small"
              fullWidth
              label="Bairro"
              value={state.neighborhood}
              name="neighborhood"
              onChange={handleChange}
            />

            <LabeledInput
              size="small"
              fullWidth
              label="Logradouro"
              value={state.street_type}
              name="street_type"
              onChange={handleChange}
            />
            <Box display={"flex"} width={"100%"} columnGap={1}>
              <LabeledInput
                size="small"
                style={{ width: "100%" }}
                fullWidth
                label="Número"
                value={state.number}
                name="number"
                onChange={handleChange}
              />

              <LabeledInput
                size="small"
                style={{ width: "100%" }}
                fullWidth
                label="Complemento"
                value={state.complement}
                name="complement"
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            width={"100%"}
            marginTop={4}
          >
            <Button
              style={{
                marginRight: "15px",
                textTransform: "none",
                fontSize: 16,
              }}
              variant="outlined"
              onClick={() => history.push("/partners/customers")}
            >
              Cancelar
            </Button>

            <Button
              style={{
                background: "#1361A4",
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: 16,
              }}
              disabled={isDisabled}
              variant="contained"
              onClick={handleSubmmit}
            >
              Salvar e Fechar
            </Button>
          </Box>
        </Box>
        {edit && <RightSideBar editClient={edit} showTabs={false} />}
      </Box>
    </Box>
  );
}
