// export const ACCOMMODATIONS = [
//     { text: 'Apto. single', value: 'APT_SINGLE' },
//     { text: 'Apto. duplo', value: 'DOUBLE_APT' },
//     { text: 'Apto. triplo', value: 'TRIPLE_APT' },
//     { text: 'Early check-in', value: 'EARLY_CHECK_IN' },
//     // { text: 'Early check-in - apto. single', value: 'EARLY_CHECK_IN_APT_SINGLE' },
//     // { text: 'Early check-in - apto. triplo', value: 'EARLY_CHECK_IN_TRIPLE_APT' },
//     { text: 'Late ckeck-out', value: 'LATE_CHECK_OUT' },
//     // { text: 'Late ckeck-out - apto. single', value: 'LATE_CHECK_OUT_APTO_SINGLE' },
//     // { text: 'Late ckeck-out - apto. triplo', value: 'LATE_CHECK_OUT_TRIPLE_AP' },
//     { text: 'Frigobar', value: 'FRIDGE' },
//     { text: 'Taxa alteração de frigobar', value: 'FRIDGE_CHANGE_FEE' },
//     // { text: 'Internet', value: 'INTERNET_IN_THE_APARTMENTS' },
//     { text: 'Maleiro', value: 'SUITCASE' },
//     { text: 'Outros', value: 'OTHERS' },
//     { text: 'Abertura de portas', value: 'WELCOMING' },
// ];
export const EVENT_LOCATIONS = [
  { text: "Recepção", value: "RECEPTION" },
  { text: "Foyer", value: "FOYER" },
  { text: "Foyer 2", value: "FOYER_2" },
  { text: "Plenária 1", value: "AUDITORIUM_1" },
  { text: "Plenária 2", value: "AUDITORIUM_2" },
  { text: "Coquetel", value: "COCKTAIL" },
  { text: "Almoço", value: "LUNCH" },
  { text: "Outros", value: "OTHERS" },
  { text: "Plenária", value: "AUDITORIUM" },
  { text: "Ativação Tecnológica", value: "TECHNOLOGICAL_ACTIVATION" },
  { text: "Ativação Gastronômica", value: "GASTRONOMIC_ACTIVATION" },
  { text: "Sala de Staff", value: "STAFF_ROOM" },
];

// export const ROOMS = [
//   { text: "Espaço para coquetel", value: "COCKTAIL_SPACE" },
//   { text: "Espaço para festa de encerramento", value: "CLOSING_PARTY_SPACE" },
//   { text: "Espaço privativo para almoço", value: "PRIVATE_LUNCH_SPACE" },
//   { text: "Espaço privativo para jantar", value: "PRIVATE_DINNER_SPACE" },
//   { text: "Taxa para alteração de lay out", value: "LAYOUT_CHANGE_FEE" },
//   { text: "Sala de Reunião", value: "MEETING_ROOM" },
// ];

export const TRANSLATIONS = [
  { text: "Equipamentos", value: "EQUIPMENT_RENTAL" }, // or EVENT_EQUIPMENT
  { text: "Intérpretes", value: "INTERPRETATION_SERVICES" },
  { text: "LIBRAS", value: "SIGN_LANGUAGE_INTERPRETERS" },
  { text: "Tradução de Texto", value: "TEXT_TRANSLATION" },
  { text: "Transcrição", value: "TRANSCRIPTION_SERVICES" },
];

export const TECHNOLOGY = [
  { text: "APP", value: "MOBILE_APPLICATION" },
  { text: "Hotsite", value: "MICROSITE" }, // or LANDING_PAGE
  { text: "Internet", value: "INTERNET_SERVICE" },
  { text: "Outros", value: "OTHER_DIGITAL_SERVICES" },
  { text: "Plataforma Digital", value: "DIGITAL_PLATFORM" },
  { text: "QR Code", value: "QR_CODE_SYSTEM" },
  { text: "Sistema de Credenciamento", value: "ACCREDITATION_SYSTEM" },
];

export const AEB = [
  { text: "Água", value: "WATER" },
  { text: "Almoço", value: "LUNCH" },
  { text: "Ativação Gastronômica", value: "GASTRONOMIC_ACTIVATION" },
  { text: "Bar de drinks", value: "DRINK_BAR" },
  { text: "Café da manhã", value: "BREAKFAST" },
  { text: "Camarim", value: "DRESSING_ROOM" },
  { text: "Coffee Break", value: "COFFEE_BREAK" },
  { text: "Coquetel", value: "COCKTAIL" },
  { text: "Frete", value: "FREIGHT" },
  { text: "Frigobar", value: "MINIBAR" },
  { text: "Happy Hour", value: "HAPPY_HOUR" },
  { text: "Jantar", value: "DINNER" },
  { text: "Lanche box", value: "SNACK_BOX" },
  { text: "Maquinário e utensilios", value: "EQUIPMENT_AND_UTENSILS" },
  { text: "Operacional", value: "OPERATIONAL" },
  { text: "Pacote de Bebidas", value: "BEVERAGE_PACKAGE" },
  { text: "Taxa de transferência", value: "TRANSFER_TAX" },
  { text: "Térmica de café", value: "COFFEE_THERMOS" },
  { text: "Térmica de chá", value: "TEA_THERMOS" },
  { text: "Welcome coffee", value: "WELCOME_COFFEE" },
  { text: "Welcome drink", value: "WELCOME_DRINK" },
];

export const PRODUCTION_SUPPORT = [
  { text: "Atendimento e produção", value: "SERVICE_AND_PRODUCTION" },
  { text: "Gerenciamento de projeto", value: "PROJECT_MANAGEMENT" },
];

export const ATTRACTION = [
  { text: "Artista", value: "ARTIST" },
  { text: "Atração musical", value: "MUSICAL_ACT" },
  { text: "Cerimonialista", value: "MASTER_OF_CEREMONIES" },
  { text: "DJ", value: "DJ" },
  { text: "Palestrante", value: "SPEAKER" },
  { text: "Team building", value: "TEAM_BUILDING" },
];

export const BRINDES_INCENTIVOS_PREMIACOES = [
  { text: "Brindes em geral", value: "PROMOTIONAL_ITEMS" },
  {
    text: "Gift card, vouchers e outros benefícios",
    value: "GIFT_CARDS_AND_VOUCHERS",
  },
  { text: "Troféus e placas", value: "TROPHIES_AND_PLAQUES" },
  { text: "Vestuário personalizado", value: "CUSTOM_APPAREL" },
];

export const CENOGRAPHY_DECORATION = [
  { text: "Ambientação", value: "ENVIRONMENT_DESIGN" },
  { text: "Ativação visual", value: "VISUAL_ACTIVATION" },
  { text: "Custos operacionais/staff", value: "OPERATIONAL_COSTS_AND_STAFF" },
  { text: "Itens cenográficos", value: "SCENOGRAPHIC_ITEMS" },
  { text: "Maquetes", value: "SCALE_MODELS" },
  { text: "Mobiliário", value: "FURNITURE" },
  { text: "Tenda", value: "TENT" },
];

export const COMMUNICATIONS = [
  { text: "3D", value: "THREE_D_MODELING" },
  { text: "Conceito", value: "CONCEPT_DESIGN" },
  { text: "Criação", value: "CREATIVE_DEVELOPMENT" },
  {
    text: "Fluxo - Categoria Comunicação - Tipo de Serviço - Sub Tipo de Serviço - Descrição",
    value: "COMMUNICATION_FLOW_SERVICES",
  },
  { text: "Planejamento", value: "PLANNING" },
];

export const CONTENT = [
  { text: "GC", value: "GRAPHIC_CONTENT" }, // or 'GENERATED_CONTENT' depending on context
  { text: "Teaser", value: "TEASER_VIDEO" },
  { text: "Vídeo", value: "VIDEO_PRODUCTION" },
  { text: "Vinheta", value: "JINGLE_OR_BRAND_STING" }, // context-dependent
];

export const EQUIPMENTS = [
  { text: "Frete", value: "FREIGHT" },
  { text: "Gerador", value: "GENERATOR" },
  { text: "Impressora", value: "PRINTER" },
  { text: "Iluminação", value: "LIGHTING" },
  { text: "Laser Pointer", value: "LASER_POINTER" },
  { text: "Mouse", value: "COMPUTER_MOUSE" },
  { text: "No-Break", value: "UPS" }, // Uninterruptible Power Supply
  { text: "Notebook", value: "LAPTOP" },
  { text: "Operacional", value: "OPERATIONAL" },
  { text: "Painel de Led", value: "LED_PANEL" },
  { text: "Plataforma Elevatória", value: "LIFT_PLATFORM" },
  { text: "Projetor", value: "PROJECTOR" },
  { text: "Praticável", value: "STAGE_PLATFORM" }, // or MODULAR_STAGE
  { text: "Processadora", value: "MEDIA_PROCESSOR" }, // or VIDEO_PROCESSOR
  { text: "Púlpito", value: "PODIUM" },
  { text: "Régua de Energia | Extensão", value: "POWER_STRIP_EXTENSION" },
  { text: "Sonorização", value: "SOUND_SYSTEM" },
  { text: "Switch | Kramer | Chaveadora", value: "VIDEO_SWITCHER" }, // or MATRIX_SWITCHER
  { text: "Teclado", value: "KEYBOARD" },
  { text: "Tela", value: "SCREEN" }, // or PROJECTION_SCREEN
  { text: "Transformador", value: "TRANSFORMER" },
  { text: "TV", value: "TELEVISION" },
  { text: "Votação eletrônica", value: "ELECTRONIC_VOTING" },
];
export const PARKING = [
  { text: "Estacionamento", value: "PARKING" },
  { text: "Lava Rápido", value: "CAR_WASH" },
  { text: "Manobrista", value: "VALET_PARKING" },
];
export const FOTO_VIDEO = [
  { text: "Cinegrafista", value: "VIDEOGRAPHER" },
  { text: "Fotógrafo", value: "PHOTOGRAPHER" },
];

export const ACCOMMODATIONS = [
  { text: "Abertura de portas", value: "DOOR_OPENING_SERVICE" },
  { text: "Apto. duplo", value: "DOUBLE_ROOM" },
  { text: "Apto. single", value: "SINGLE_ROOM" },
  { text: "Apto. triplo", value: "TRIPLE_ROOM" },
  { text: "Early check-in", value: "EARLY_CHECK_IN" },
  { text: "Frigobar", value: "MINIBAR" },
  { text: "Late check-out", value: "LATE_CHECK_OUT" },
  { text: "Maleiro", value: "BELLMAN_SERVICE" }, // or PORTER_SERVICE
  { text: "Outros", value: "OTHER_SERVICES" },
  { text: "Taxa alteração de frigobar", value: "MINIBAR_RESTOCKING_FEE" },
];

export const SUBSCRIPTION = [
  { text: "CREDENCIAMENTO STAFF", value: "STAFF_ACCREDITATION" },
  { text: "INSCRIÇÃO", value: "REGISTRATION" },
  {
    text: "Inscrição médico não sócio",
    value: "NON_MEMBER_DOCTOR_REGISTRATION",
  },
  { text: "Inscrição médico sócio", value: "MEMBER_DOCTOR_REGISTRATION" },
  { text: "Inscrição residente", value: "RESIDENT_REGISTRATION" },
  { text: "Taxa de inscrição", value: "REGISTRATION_FEE" },
];

export const LICENCES_TAXES = [
  { text: "ART/RRT", value: "TECHNICAL_RESPONSIBILITY_TERM" }, // or ART_RRT
  { text: "ECAD", value: "COPYRIGHT_FEE" }, // Brazilian copyright collection
  { text: "Licenças, Alvarás e Documentação", value: "LICENSES_AND_PERMITS" },
  { text: "Ponto de Água", value: "WATER_HOOKUP_FEE" },
  { text: "Taxa de Energia/KVA", value: "ENERGY_FEE_PER_KVA" },
  { text: "Taxa de Fiscalização", value: "INSPECTION_FEE" },
  { text: "Taxa de Limpeza", value: "CLEANING_FEE" },
];

// export const EQUIPMENTS = [
//     { text: 'Caixa amplificada', value: 'AMPLIFIED_BOX' },
//     { text: 'Cartucho para impressora', value: 'PRINTER_REFIL' },
//     { text: 'Chaveador eletrônico', value: 'ELETRONIC_SWITCH' },
//     { text: 'Flip chart', value: 'FLIP_CHART' },
//     { text: 'Full lighting for plenary room', value: 'FULL_LIGHTING_PLENARY_ROOM' },
//     { text: 'Gooseneck microphone', value: 'GOOSENECK_MIC' },
//     {
//         text: 'Grid in box truss Q25 to structure lighting and projection',
//         value: 'BOX_GRID_FOR_PROJECTION_LIGHTING_STRUCTURING'
//     },
//     { text: 'Iluminação', value: 'LIGHTING' },
//     { text: 'Iluminação básica para sala plenária', value: 'BASIC_LIGHTING_PLENARY_ROOM' },
//     { text: 'Iluminação completa para sala plenária', value: 'FULL_LIGHTING_PLENARY_ROOM' },
//     { text: 'Iluminação para ambientação de sala', value: 'ROOM_AMBIENCE_LIGHTING' },
//     { text: 'Iluminação para balada / dj', value: 'NIGHTCLUB_LIGHTING' },
//     { text: 'Iluminação para palco', value: 'STAGE_LIGHTING' },
//     { text: 'Impressora laser colorida', value: 'LASER_PRINTER_COLOR' },
//     { text: 'Impressora laser preta e branco', value: 'LASER_PRINTER_B_AND_W' },
//     { text: 'Inch screen', value: 'INCHES_SCREEN' },
//     { text: 'Inch screen with lycra-wrapped box truss', value: '100_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
//     { text: 'Inch screen with lycra-wrapped box truss', value: '120_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
//     { text: 'Inch screen with lycra-wrapped box truss', value: '150_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
//     { text: 'Inch screen with lycra-wrapped box truss', value: '180_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
//     { text: 'Inch screen with lycra-wrapped box truss', value: '200_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
//     { text: 'Inch screen with lycra-wrapped box truss', value: '250_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
//     { text: 'Kramer electronic switch', value: 'KRAMER_SWITCHER' },
//     { text: 'Kramer video matrix', value: 'KRAMER_VIDEO_MATRIX' },
//     { text: 'Lapel microphone', value: 'LAPEL_MIC' },
//     { text: 'LCD TV 32" (pedestal)', value: '32_INCHES_TV_PEDESTAL' },
//     { text: 'LCD TV 32" (stage return)', value: '32_INCHES_TV_STAGE_RETURN' },
//     { text: 'LCD TV 42" (stage return)', value: '42_INCHES_TV_STAGE_RETURN' },
//     { text: 'LCD TV 50" (pedestal)', value: '50_INCHES_TV_PEDESTAL' },
//     { text: 'LCD TV 50" (stage return)', value: '50_INCHES_TV_STAGE_RETURN' },
//     { text: 'Lighting for stage', value: 'STAGE_LIGHTING' },
//     { text: 'Microfone para púlpito', value: 'PULPIT_MIC' },
//     { text: 'Microfone sem fio', value: 'WIRELLESS_MIC' },
//     { text: 'Mouse sem fio com laser pointer', value: 'WIRELESS_MOUSE_WITH_POINTER' },
//     { text: 'Nightclub lighting', value: 'NIGHTCLUB_LIGHTING' },
//     { text: 'Notebook', value: 'NOTEBOOK' },
//     { text: 'Operator fee', value: 'OPERATOR_FEE' },
//     { text: 'Operator technician (assembly/disassembly)', value: 'TECHNINAL_OPERATOR_ASSEMBLY_DISASSEMBLY' },
//     { text: 'Operator technician', value: 'TECHNINAL_OPERATOR' },
//     {
//         text: 'Painel em box truss Q15 envelopado com lycra preta para sustentação da tela',
//         value: 'BOX_PANEL_FOR_LIGHTING_SUPPORT'
//     },
//     { text: 'Power ruler', value: 'POWER_RULER' },
//     { text: 'Praticável', value: 'PRACTICABLE' },
//     { text: 'Projector', value: 'PROJECTOR' },
//     { text: 'Projector 3000 ansi lumens', value: 'PROJECTOR_3000_ANSI_LUMENS' },
//     { text: 'Projector 4500 ansi lumens', value: 'PROJECTOR_4500_ANSI_LUMENS' },
//     { text: 'Projector 5000 ansi lumens', value: 'PROJECTOR_5000_ANSI_LUMENS' },
//     { text: 'Projector 5500 ansi lumens', value: 'PROJECTOR_5500_ANSI_LUMENS' },
//     { text: 'Projector 6500 ansi lumens', value: 'PROJECTOR_6500_ANSI_LUMENS' },
//     { text: 'Radio communicator', value: 'RADIO_COMMUNICATOR' },
//     { text: 'Refil para impressora', value: 'PRINTER_REFIL' },
//     { text: 'Régua de energia', value: 'POWER_RULER' },
//     { text: 'Room ambience lighting', value: 'ROOM_AMBIENCE_LIGHTING' },
//     { text: 'Screen', value: 'SCREEN' },
//     { text: 'Screen 32"', value: '32_INCHES_SCREEN' },
//     { text: 'Screen 42"', value: '42_INCHES_SCREEN' },
//     { text: 'Screen 50"', value: '50_INCHES_SCREEN' },
//     { text: 'Screen 65"', value: '65_INCHES_SCREEN' },
//     { text: 'Screen 75"', value: '75_INCHES_SCREEN' },
//     { text: 'Screen 85"', value: '85_INCHES_SCREEN' },
//     { text: 'Screen 100"', value: '100_INCHES_SCREEN' },
//     { text: 'Screen 120"', value: '120_INCHES_SCREEN' },
//     { text: 'Screen 150"', value: '150_INCHES_SCREEN' },
//     { text: 'Screen 180"', value: '180_INCHES_SCREEN' },
//     { text: 'Screen 200"', value: '200_INCHES_SCREEN' },
//     { text: 'Screen 250"', value: '250_INCHES_SCREEN' },
//     { text: 'Screen with lycra-wrapped box truss', value: 'SCREEN_WITH_LYCRA_WRAPPED_BOX' },
//     { text: 'Switcher kramer', value: 'KRAMER_SWITCHER' },
//     { text: 'T-shirt', value: 'T_SHIRT' },
//     { text: 'Tap house', value: 'TAP_HOUSE' },
//     { text: 'Taxa de transporte', value: 'TRANSPORT_FEE' },
//     { text: 'TV', value: 'TV' },
//     { text: 'TV LCD 32" (pedestal)', value: '32_INCHES_TV_PEDESTAL' },
//     { text: 'TV LCD 32" (stage return)', value: '32_INCHES_TV_STAGE_RETURN' },
//     { text: 'TV LCD 42" (stage return)', value: '42_INCHES_TV_STAGE_RETURN' },
//     { text: 'TV LCD 50" (pedestal)', value: '50_INCHES_TV_PEDESTAL' },
//     { text: 'TV LCD 50" (stage return)', value: '50_INCHES_TV_STAGE_RETURN' },
//     { text: 'Voucher creation - Backdrop', value: 'CREATION_BACKDROP' },
//     { text: 'Voucher creation - Balcony', value: 'CREATION_BALCONY' },
//     { text: 'Voucher creation - Digital or printed', value: 'CREATION_DIGITAL_PRINTED_VOUCHER' },
//     { text: 'Voucher creation - Digital or printed certificate', value: 'CREATION_DIGITAL_PRINTED_CERTIFICATE' },
//     { text: 'Voucher creation - Emkt', value: 'CREATION_EMKT' },
//     { text: 'Voucher creation - Envelope', value: 'CREATION_ENVELOPE' },
//     { text: 'Voucher creation - Static and animated vinheta', value: 'CREATION_ANIMATED_JINGLE' },
//     { text: 'Voucher creation - Stickers', value: 'CREATION_STICKERS' },
//     { text: 'Voucher creation - T-shirt', value: 'CREATION_SHIRT' },
//     { text: 'Wired microphone', value: 'WIRED_MIC' },
//     { text: 'Wireless microphone', value: 'WIRELESS_MIC' },
//     { text: 'Wireless mouse with laser pointer', value: 'WIRELESS_MOUSE_WITH_POINTER' },
// ];
// export const COMMUNICATIONS = [
//     { text: 'Criação - Adaptação de formato de fotos (não é tratamento)', value: 'CREATION_PHOTO_ADAPTATION' },
//     { text: 'Criação - Adesivos', value: 'CREATION_STICKERS' },
//     { text: 'Criação - Apresentação (PPT)', value: 'CREATION_PPT_PRESENTATUIB' },
//     { text: 'Criação - Area instagramavel', value: 'CREATION_INSTAGRAM_AREA' },
//     { text: 'Criação - Backdrop', value: 'CREATION_BACKDROP' },
//     { text: 'Criação - Balcony', value: 'CREATION_BALCONY' },
//     { text: 'Criação - Banner impresso e digital', value: 'CREATION_DIGITAL_PRINTED_BANNER' },
//     { text: 'Criação - BG\'s', value: 'CREATION_BGS' },
//     { text: 'Criação - Brindes', value: 'CREATION_GIFTS' },
//     { text: 'Criação - Cardapio', value: 'CREATION_MENU' },
//     { text: 'Criação - Certificado impresso ou digital', value: 'CREATION_DIGITAL_PRINTED_CERTIFICATE' },
//     { text: 'Criação - Convites impressos', value: 'CREATION_PRINTED_INVITATION' },
//     { text: 'Criação - Cordão de crachá', value: 'CREATION_BADGE_STRING' },
//     { text: 'Criação - Crachá', value: 'CREATION_BADGE' },
//     { text: 'Criação - GC\'S', value: 'CREATION_GCS' },
//     { text: 'Criação - Hotsite/LandingPage', value: 'CREATION_LANDING_PAGE' },
//     { text: 'Criação - Kv', value: 'CREATION_KV' },
//     { text: 'Criação - Kv Animado', value: 'CREATION_ANIMATED_KV' },
//     { text: 'Criação - LandingPage', value: 'CREATION_LANDING_PAGE' },
//     { text: 'Criação - Máscaras', value: 'CREATION_MASKS' },
//     { text: 'Criação - Materias gráficos (ficha de palco, carta, folder, flyer)', value: 'CREATION_GRAPHIC_MATERIAL' },
//     { text: 'Criação - Molduras', value: 'CREATION_FRAMES' },
//     { text: 'Criação - Outros', value: 'CREATION_OTHERS' },
//     { text: 'Criação - Peças cenografia', value: 'CREATION_SCENOGRAPHY_PLAY' },
//     { text: 'Criação - Placas', value: 'CREATION_PLATE' },
//     { text: 'Criação - Plataforma/Hotsite/LandingPage', value: 'CREATION_LANDING_PAGE' },
//     { text: 'Criação - Post', value: 'CREATION_POST' },
//     { text: 'Criação - Prisma mesa', value: 'CREATION_PRISM' },
//     { text: 'Criação - Pulseira', value: 'CREATION_BRACELET' },
//     { text: 'Criação - Tag', value: 'CREATION_TAG' },
//     { text: 'Criação - Template', value: 'CREATION_TEMPLATE' },
//     { text: 'Criação - Testeiras', value: 'CREATION_FOREHEADS' },
//     { text: 'Criação - Totem impresso e digital', value: 'CREATION_DIGITAL_PRINTED_TOTEM' },
//     { text: 'Criação - Troféu', value: 'CREATION_TROPHY' },
//     { text: 'Criação - Voucher impresso ou digital', value: 'CREATION_DIGITAL_PRINTED_VOUCHER' },
//     { text: 'Criação - Vinheta estatica e animada', value: 'CREATION_ANIMATED_JINGLE' },
// ];

// export const TRANSLATIONS = [
//   { text: "Equipamentos", value: "EQUIPMENTS" },
//   { text: "Intérpretes", value: "TRANSLATORS" },
// ];

export const SUPPORT = [
  { text: "Administrador(a)", value: "ADMIN" },
  { text: "Coordenador(a)", value: "COORDINATOR" },
  { text: "Despesa de alimentação", value: "FOOD_EXPENSE" },
  { text: "Despesa de transporte", value: "TRANSPORT_EXPENSE" },
  { text: "Diretor Técnico", value: "TECHNICAL_DIRECTOR" },
  { text: "Diretor Artístico", value: "ARTISTIC_DIRECTOR" },
  { text: "Produtor", value: "PRODUCER" },
  { text: "Recepcionista", value: "RECEPTIONIST" },
  { text: "Roteirista", value: "SCREENWRITER" },
];

export const GRAPHIC_MATERIAL = [{ text: "Serviços", value: "SERVICES" }];
export const MOUNTING_COMPANY = [{ text: "Serviços", value: "SERVICES" }];
export const TRAVEL_INSURANCE = [{ text: "Serviços", value: "SERVICES" }];
export const EVENT_INSURANCE = [{ text: "Serviços", value: "SERVICES" }];

export const NECESSARY_ADDITIONS = [
  { text: "99Taxi", value: "TAXI_SERVICE" }, // or NINETYNINE_TAXI for brand specificity
  { text: "Motoboy", value: "MOTORCYCLE_COURIER" },
  { text: "Outros", value: "OTHER_TRANSPORT" },
  { text: "Transportadora", value: "CARGO_TRANSPORT" }, // or FREIGHT_SERVICE
];

export const TRANSFER = [
  { text: "Coordenação aeroporto", value: "AIRPORT_COORDINATION" },
  { text: "Passeio", value: "EXCURSION" }, // or SIGHTSEEING_TOUR
  { text: "Transfer extra", value: "ADDITIONAL_TRANSFER" },
  { text: "Transfer in", value: "ARRIVAL_TRANSFER" },
  { text: "Transfer out", value: "DEPARTURE_TRANSFER" },
];

export const SERVICE_SUPPORT = [
  // Health & Emergency
  { text: "Ambulância", value: "AMBULANCE_SERVICE" },
  { text: "Cilindros Ar Medicinal", value: "MEDICAL_OXYGEN_TANKS" },
  { text: "Paramédicos", value: "PARAMEDICS" },

  // Safety Personnel
  { text: "Brigadista", value: "FIRE_SAFETY_TECHNICIAN" },
  { text: "Equipe de Segurança", value: "SECURITY_TEAM" },
  { text: "Segurança do Trabalho", value: "OCCUPATIONAL_SAFETY" },
  { text: "Extintores e EPI", value: "FIRE_EXTINGUISHERS_AND_PPE" },

  // Operational Staff
  { text: "Carregador", value: "PORTER_SERVICE" },
  { text: "Equipe de Limpeza", value: "CLEANING_TEAM" },
  { text: "Maleiro", value: "BELLHOP_SERVICE" },
  { text: "Manuseio", value: "MATERIAL_HANDLING" },
  { text: "Manutencista e Eletricista", value: "MAINTENANCE_AND_ELECTRICIAN" },

  // Event Staff
  { text: "Maquiador", value: "MAKEUP_ARTIST" },
  { text: "Modelo", value: "MODEL" },
  { text: "Recepcionista", value: "RECEPTIONIST" },
  { text: "Recreador", value: "ENTERTAINER" }, // or EVENT_ANIMATOR
];

export const HUMAN_RESOURCES_STAFF = [
  { text: "Coordenador EXT", value: "EXTERNAL_COORDINATOR" },
  { text: "Coordenador INT", value: "INTERNAL_COORDINATOR" },
  { text: "Diretor Artístico", value: "ARTISTIC_DIRECTOR" },
  { text: "Diretor Técnico", value: "TECHNICAL_DIRECTOR" },
  { text: "Produtor EXT", value: "EXTERNAL_PRODUCER" },
  { text: "Produtor INT", value: "INTERNAL_PRODUCER" },
  { text: "Reembolso de Staff", value: "STAFF_REIMBURSEMENT" },
  { text: "Roteirista", value: "SCRIPTWRITER" },
  { text: "Sonoplasta", value: "SOUND_DESIGNER" }, // or AUDIO_ENGINEER
];

export const RSVP = [
  { text: "Anuidade", value: "ANNUAL_MEMBERSHIP_FEE" },
  { text: "Licença de Usuário", value: "USER_LICENSE_FEE" },
  { text: "Mensalidade", value: "MONTHLY_SUBSCRIPTION" },
  {
    text: "RSVP emergencial Internacional",
    value: "INTERNATIONAL_EMERGENCY_RSVP",
  },
  { text: "RSVP emergencial Nacional", value: "NATIONAL_EMERGENCY_RSVP" },
  { text: "RSVP Internacional", value: "INTERNATIONAL_RSVP" },
  { text: "RSVP Nacional", value: "NATIONAL_RSVP" },
];
// export const SUBSCRIPTION = [

//     { text: 'Inscrição médico sócio', value: 'MEMBER_MEDICAL_REGISTRATION' },
//     {
//         text: 'Inscrição médico não sócio',
//         value: 'NON_MEMBER_MEDICAL_REGISTRATION',
//     },
//     { text: 'Inscrição residente', value: 'RESIDENT_ENROLLMENT' },
//     { text: 'Taxa de inscrição', value: 'REGISTRATION_FEE' },
// ];

// export const RSVP = [
//   { text: "RSVP", value: "RSVP" },
//   { text: "RSVP emergencial", value: "RSVP_EMERGENCIAL" },
// ];

export const ROOMS = [
  { text: "Espaço para coquetel", value: "COCKTAIL_RECEPTION_SPACE" },
  { text: "Espaço para festa de encerramento", value: "CLOSING_PARTY_VENUE" },
  { text: "Espaço privativo para almoço", value: "PRIVATE_LUNCH_SPACE" },
  { text: "Espaço privativo para jantar", value: "PRIVATE_DINNER_SPACE" },
  { text: "PLENÁRIA", value: "PLENARY_ROOM" },
  { text: "RESTAURANTE", value: "RESTAURANT_VENUE" },
  { text: "SALA", value: "MEETING_ROOM" }, // Note: Corrected typo to "ROOM" in value
  { text: "Taxas extras", value: "ADDITIONAL_VENUE_FEES" },
];

export const AIR = [
  { text: "Previsão por pessoa", value: "AERIAL_FORECAST_PER_PERSON" },
  { text: "Seguir planilha aérea", value: "AERIAL_WORKSHEET" },
  { text: "Seguro viagem", value: "TRAVEL_INSURANCE" },
  { text: "Taxa de emissão", value: "ISSUANCE_FEE" },
];

// export const TRANSFER = [
//   { text: "Aeroporto / hotel / aeroporto - previsão", value: "" },
//   { text: "City tour", value: "CITY_TOUR" },
//   { text: "Coordenação aeroporto", value: "AIRPORT_COORDINATION" },
//   { text: "Hotel / restaurante / hotel", value: "HOTEL_RESTAURANT_HOTEL" },
//   { text: "Transfer extra", value: "TRANFER_EXTRA" },
//   { text: "Transfer in", value: "TRANFER_IN" },
//   { text: "Transfer out", value: "TRANFER_OUT" },
//   {
//     text: "Transfer residência / aeroporto / residência",
//     value: "RESIDENCE_AIRPORT_RESIDENCE",
//   },
//   {
//     text: "Transfer residência / hotel / residência",
//     value: "RESIDENCE_HOTEL_RESIDENCE",
//   },
// ];

export const SEVERAL = [
  {
    text: "Decoração para festa de encerramento",
    value: "CLOSING_PARTY_DECORATION",
  },
  { text: "DJ para o festa de encerramento", value: "CLOSING_PARTY_DJ" },
  { text: "Ecad para banda/dj", value: "" },
  { text: "Estacionamento", value: "PARKING" },
  { text: "Fotógrafo", value: "PHOTOGRAPHER" },
  {
    text: "Impressão de materiais gráficos",
    value: "GRAPHIC_MATERIAL_PRINTING",
  },
  { text: "Limpeza", value: "CLEANING" },
  { text: "Link dedicado", value: "DEDICATED_LINK" },
  { text: "Lunch box", value: "LUNCH_BOX" },
  { text: "Manobrista", value: "WALLET" },
  { text: "Mobiliário", value: "FURNITURE" },
  { text: "Pesquisa de satisfação", value: "SATISFACTION_SURVEY" },
  { text: "Ponto de internet", value: "ACCESS_POINT" },
  { text: "Ramal telefônico", value: "TELEPHONE_EXTENSION" },
  { text: "Segurança", value: "SECURITY" },
  { text: "Seguro Viagem", value: "TRAVEL_INSURANCE" },
  { text: "Stand", value: "STAND" },
  { text: "Telefônia", value: "TELEPHONY" },
];
interface StringMap {
  [key: string]: any;
}
export const ServiceOptions: StringMap = {
  ACCOMMODATIONS: ACCOMMODATIONS,
  ROOMS: ROOMS,
  AEB: AEB,
  EQUIPMENTS: EQUIPMENTS,
  COMMUNICATIONS: COMMUNICATIONS,
  TRANSLATIONS: TRANSLATIONS,
  SUPPORT: SUPPORT,
  SUBSCRIPTION: SUBSCRIPTION,
  RSVP: RSVP,
  AIR: AIR,
  TRANSFER: TRANSFER,
  SEVERAL: SEVERAL,
  PRODUCTION_SUPPORT: PRODUCTION_SUPPORT,
  ATTRACTION: ATTRACTION,
  BRINDES_INCENTIVOS_PREMIACOES: BRINDES_INCENTIVOS_PREMIACOES,
  CENOGRAPHY_DECORATION: CENOGRAPHY_DECORATION,
  CONTENT: CONTENT,
  PARKING: PARKING,
  FOTO_VIDEO: FOTO_VIDEO,
  LICENCES_TAXES: LICENCES_TAXES,
  GRAPHIC_MATERIAL: GRAPHIC_MATERIAL,
  MOUNTING_COMPANY: MOUNTING_COMPANY,
  NECESSARY_ADDITIONS: NECESSARY_ADDITIONS,
  HUMAN_RESOURCES_STAFF: HUMAN_RESOURCES_STAFF,
  TRAVEL_INSURANCE: TRAVEL_INSURANCE,
  SERVICE_SUPPORT: SERVICE_SUPPORT,
  EVENT_INSURANCE: EVENT_INSURANCE,
  TECHNOLOGY: TECHNOLOGY,
};
