import React, { useMemo } from "react";
import { useTable, usePagination, useFilters, useExpanded } from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Collapse,
} from "@mui/material";
import { FaChevronDown, FaChevronRight, FaCaretDown, FaCaretRight } from "react-icons/fa";
import TablePaginationActions from "../CustomDataGrid/ActionsComponent";
import { ColumnFilter } from "../CustomDataGrid/ColumnFilter";
import { InfoDiv } from "../CustomDataGrid/styles";

export interface IExpandableTable<T> {
  columns: any;
  data: T[];
  isLoading: boolean;
  onPageChange?(page: number): void;
  onRowsPerPageChange?(limit: number): void;
  total?: number;
  givenPage?: number;
  rowsPerPage?: number;
  renderSubComponent?: (row: any) => React.ReactNode;
  getRowCanExpand?: (row: any) => boolean;
}

export function ExpandableTableComponent<T extends readonly object[]>({
  columns,
  data,
  isLoading,
  onPageChange,
  onRowsPerPageChange,
  total,
  givenPage,
  rowsPerPage,
  renderSubComponent,
  getRowCanExpand,
}: IExpandableTable<T>) {
  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);

  const defaultColumn = useMemo(
    () => ({
      Filter: ColumnFilter,
      disableSortBy: true,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize, expanded },
  } = useTable(
    {
      data: rowData,
      defaultColumn,
      columns: columnData,
      autoResetExpanded: false,
      disableSortBy: true,
    },
    useFilters,
    useExpanded,
    usePagination
  );

  function filterRowsByPage(items: any[], pageSize: number, pageIndex: number) {
    if (givenPage !== undefined) return items;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    const filteredItems = items.slice(startIndex, endIndex);
    return filteredItems;
  }

  return (
    <div style={{ width: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "none",
          "& .MuiTable-root": {
            borderCollapse: "collapse",
            border: "none",
            tableLayout: "fixed",
            width: "100%",
          },
          "& .MuiTableCell-root": {
            transition: "all 0.3s ease",
          },
        }}
      >
        <Table stickyHeader {...getTableProps()} size="small">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    sx={{
                      padding: "8px 0",
                      width: column.width || "auto",
                      textAlign: "left",
                      height: "40px",
                      fontWeight: "700",
                      fontSize: "14px",
                      color: "#4B465C",
                    }}
                  >
                    {column.render("Header")}
                    {column.canFilter ? column.render("Filter") : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          {data.length > 0 ? (
            <TableBody {...getTableBodyProps()}>
              {filterRowsByPage(rows, rowsPerPage || pageSize, givenPage || pageIndex).map(
                (row) => {
                  prepareRow(row);

                  return (
                    <React.Fragment key={row.id}>
                      <TableRow {...row.getRowProps()} hover={false}>
                        {row.cells.map((cell: any) => (
                          <TableCell
                            {...cell.getCellProps()}
                            sx={{
                              padding: "8px 0",
                              textAlign: "left",
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              height: "40px",
                              width: cell.column.width || "auto",
                              verticalAlign: "middle",
                            }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>

                      {row.isExpanded && renderSubComponent && (
                        <TableRow>
                          <TableCell
                            colSpan={visibleColumns.length}
                            style={{
                              padding: 0,
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {renderSubComponent(row)}
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                }
              )}
            </TableBody>
          ) : isLoading ? (
            <InfoDiv>
              <CircularProgress />
            </InfoDiv>
          ) : (
            <InfoDiv>Sem dados para listar</InfoDiv>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={total || data.length}
        page={givenPage || pageIndex}
        sx={{
          ".MuiTablePagination-spacer": {
            display: "none",
          },
          ".MuiTablePagination-toolbar": {
            padding: "0 3px",
          },
          border: "none",
          boxShadow: "none",
        }}
        onPageChange={(event, newPage) => {
          if (!!onPageChange) return onPageChange(newPage);
          gotoPage(newPage);
        }}
        rowsPerPage={rowsPerPage || pageSize}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to, count }) => {
          if (!!givenPage && !!rowsPerPage && !!total) {
            let from = givenPage * rowsPerPage;
            let to = from + rowsPerPage > total ? total : from + rowsPerPage;
            return `${from} - ${to} de ${total}`;
          }
          return `${from} - ${to} de ${count}`;
        }}
        onRowsPerPageChange={(event) => {
          if (!!onRowsPerPageChange) return onRowsPerPageChange(Number(event.target.value));
          setPageSize(Number(event.target.value));
        }}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}

export default ExpandableTableComponent;
