import { Box, CircularProgress, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { api } from "../../../services/apiClient";
import { LabeledAutoComplete } from "../../UI/LabeledAutoComplete";

export function AirportSearch(props: any) {
  const [airports, setAirports] = useState<any[]>([]);
  const [selectedAirport, setSelectedAirport] = useState({
    _id: "",
    iataCode: "-",
    name: "-",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const loading = open && airports.length < 1;

  function airportSelectionHandler(newValue: any) {
    if (newValue !== null) {
      setSelectedAirport(newValue);
      props.onSelectAirport(newValue._id, props.name);
      setSearchTerm(`${newValue.iataCode} - ${newValue.name}`);
    } else {
      setSelectedAirport({
        _id: "",
        iataCode: "-",
        name: "-",
      });
      setSearchTerm("");
      props.onSelectAirport(null, props.name);
    }
  }
  useEffect(() => {
    if (searchTerm.length < 3) {
      setAirports([]);
      return;
    }
    const delayOnSearch = setTimeout(async () => {
      let response = await api.get("/airport/get-by-search", {
        params: {
          searchTerm,
        },
      });
      if (response.data.length > 0) setAirports(response.data);
    }, 1000);
    return () => clearTimeout(delayOnSearch);
  }, [searchTerm]);

  useEffect(() => {
    async function loadAirport(id: string) {
      let response = await api.get(`/airport/${props.apiData}`);
      if (response.data) {
        setSelectedAirport(response.data);
        setSearchTerm(`${response.data.iataCode} - ${response.data.name}`);
      }
    }

    if (!!props.apiData) loadAirport(props.apiData);
  }, [props.apiData]);

  return (
    <Box display={"flex"} alignItems={"center"} width={"100%"}>
      <LabeledAutoComplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={airports}
        // value={selectedAirport}
        inputValue={searchTerm}
        disablePortal
        getOptionLabel={(option) => `${option.iataCode} - ${option.name}` || ""}
        size="small"
        label={props.label}
        noOptionsText={searchTerm.length < 3 ? "Digite 3 ou mais letras" : ""}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              onChange={(
                e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setSearchTerm(e.target.value);
              }}
              placeholder={props?.placeholder || props.label}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          );
        }}
        onChange={(e, newValue: any) => {
          airportSelectionHandler(newValue);
          if (newValue !== null) {
            setSelectedAirport(newValue);
          }
        }}
      />
    </Box>
  );
}
