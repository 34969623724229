import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaCaretDown } from "react-icons/fa";
import * as yup from "yup";

import { useProject } from "../../../../../contexts/project.context";
import CurrencyInputForm from "../../../../CurrencyInputForm";
import { Input } from "../../../../Input";
import { RadioGroup } from "../../../../UI/RadioGroup";
import { Switch } from "../../../../UI/Switch";
import YesOrNoSwitch from "../../../../UI/YesOrNoSwitch";
import { ReactComponent as CompleteCircleIcon } from "../../../../../assets/svg/project/circle-arrow-complete.svg";

const earningTypes: any[] = [
  { label: "Nota fiscal", value: "INVOICE" },
  { label: "Nota de débito", value: "DEBIT_NOTE" },
];
const paymentTypes: any[] = [
  {
    label: "Faturado para o cliente, faturado no fornecedor (Gera fatura)",
    value: "BILLED_TO_CLIENT_BILLED_TO_SUPPLIER",
  },
  {
    label:
      "Faturado para o cliente, com cartão de crédito no fornecedor (Gera fatura)",
    value: "BILLED_TO_CLIENT_CREDIT_CARD_SUPPLIER",
  },
  {
    label: "Faturado para o cliente, com invoice pro fornecedor (Gera fatura)",
    value: "BILLED_TO_CLIENT_INVOICE_SUPPLIER",
  },
  {
    label: "Despesa interna, faturada no fornecedor (Gera fatura)",
    value: "INTERNAL_EXPENSE_BILLED_TO_SUPPLIER",
  },
  {
    label:
      "Cartão de crédito do cliente, com cartão de crédito no fornecedor (Apenas Fee)",
    value: "CLIENT_CREDIT_CARD_SUPPLIER_CREDIT_CARD",
  },
  { label: "Pagamento direto (Apenas Fee)", value: "DIRECT_PAYMENT" },
];

const validationSchema = yup.object({
  customerBudget: yup.number(),
  paymentTerm: yup.string(),
  customerBudgetObservations: yup.string(),
  acceptAdvances: yup.boolean(),
  advanceObservations: yup.string(),
  hasDrawnRisk: yup.boolean(),
  monthlyTax: yup.number(),
  drawnRiskObservations: yup.string(),
  makesDirectPayments: yup.boolean(),
  directPaymentsObservations: yup.string(),
  fee: yup.number(),
  paymentMethod: yup.string(),
  paymentMethodObservations: yup.string(),
  briefingObservations: yup.string(),
  observationsEvent: yup.string(),
});

const BriefingAccordion = () => {
  const { event } = useProject();
  const methods = useForm({
    defaultValues: {
      customerBudget: event?.customerBudget,
      customerBudgetObservations: event?.customerBudgetObservations,
      paymentTerm: event?.paymentTerm,
      acceptAdvances: true,
      advanceObservations: event?.advanceObservations,
      hasDrawnRisk: event?.hasDrawnRisk,
      monthlyTax: event?.monthlyTax,
      drawnRiskObservations: event?.drawnRiskObservations,
      makesDirectPayments: event?.makesDirectPayments,
      fee: event?.fee,
      paymentMethod: event?.paymentMethod,
      paymentMethodObservations: event?.paymentMethodObservations,
      briefingObservations: event?.briefingObservations,
      directPaymentsObservations: event?.directPaymentsObservations,
      observationsEvent: event?.observationsEvent,
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });
  const { register } = methods;
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      style={{ borderRadius: 4 }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<FaCaretDown color="white" />}
        id="panel1a-header"
        style={{
          maxHeight: "40px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#3F485A",
          borderRadius: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
            }}
          >
            <CompleteCircleIcon />
            <span style={{ color: "white" }}>Briefing - Dados do Evento</span>
          </div>

          {/* <div
            style={{ color: "white", marginRight: 16, cursor: "pointer" }}
            onClick={(e) => e.stopPropagation()}
          >
            Salvar
          </div> */}
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <FormProvider {...methods}>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              marginBottom: 2,
              p: 1,
              border: "1px solid #eee",
              borderRadius: 3,
            }}
          >
            <Box width={"30%"} mt={2}>
              <CurrencyInputForm
                label="Bugdet do cliente"
                {...register("customerBudget")}
                disabled
              />
            </Box>

            <Input
              label="Observações"
              {...register("paymentTerm")}
              style={{ width: "100%" }}
              disabled
            />

            {/* <Input label="Honorários" disabled {...register("fee")} />  </Box>*/}

          <Box border={"1px solid #eee"} p={1.5} borderRadius={3}>
            <span style={{ fontSize: "14px" }}>Budget do Cliente</span>
            <Box display={"flex"} gap={4} alignItems={"baseline"}>
              <Box width={"30%"}>
                <CurrencyInputForm
                  label="Valor"
                  name="customerBudget"
                  disabled
                />
              </Box>
              <Input
                label="Observações"
                placeholder="Insira mais detalhes"
                {...register("customerBudgetObservations")}
                fullWidth
                disabled
              />
            </Box>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={0.5}
            border={"1px solid #E0E0E0"}
            borderRadius={1}
            padding={"12px 16px"}
          >
            <Box
              display="flex"
              flexDirection={"row"}
              gap={1}
              flex={1}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
            >
              <span
                style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}
              >
                Faz adiantamento ?
              </span>
              <YesOrNoSwitch
                setValue={() => null}
                value={event?.acceptAdvances}
                type=""
              />
            </Box>

            <Input
              label="Observações"
              disabled
              {...register("advanceObservations")}
            />
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={0.5}
            border={"1px solid #E0E0E0"}
            borderRadius={1}
            padding={"12px 16px"}
            marginTop={2}
          >
            <Box
              display="flex"
              flexDirection={"row"}
              gap={1}
              flex={1}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
            >
              <span
                style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}
              >
                Possui risco sacado ?
              </span>
              <YesOrNoSwitch
                setValue={() => null}
                value={event?.hasDrawnRisk}
                type=""
              />
            </Box>

            <Box display={"flex"} flex={1} gap={3} sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", flex: 1 }}>
                <Input
                  label="Taxa mensal"
                  disabled
                  {...register("monthlyTax")}
                />
              </Box>

              <Box sx={{ display: "flex", flex: 4 }}>
                <Input
                  label="Observações"
                  disabled
                  {...register("drawnRiskObservations")}
                />
              </Box>
            </Box>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={0.5}
            border={"1px solid #E0E0E0"}
            borderRadius={1}
            padding={"12px 16px"}
            marginTop={2}
          >
            <Box
              display="flex"
              flexDirection={"row"}
              gap={1}
              flex={1}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
            >
              <span
                style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}
              >
                Paga fornecedores diretamente ?
              </span>
              <YesOrNoSwitch
                setValue={() => null}
                value={event?.makesDirectPayments}
                type=""
              />
            </Box>

            <Input
              label="Observações"
              disabled
              {...register("directPaymentsObservations")}
            />
          </Box>

          <Box display={"flex"} alignItems={"center"} marginTop={2}>
            <RadioGroup.Root
              label="Forma de faturamento"
              value={
                earningTypes.find((item) => item.value === event?.billingMethod)
                  ?.value
              }
            >
              {earningTypes.map(({ value, label }, index) => (
                <RadioGroup.Item
                  key={index}
                  label={label}
                  value={value}
                  disabled={!event?.billingMethod}
                />
              ))}
            </RadioGroup.Root>
          </Box>

          {/* <Box display={"flex"} alignItems={"center"} marginTop={2}>
            <RadioGroup.Root
              label="Forma de pagamento"
              value={
                paymentTypes.find((item) => item.value === event?.paymentMethod)
                  ?.value
              }
            >
              {paymentTypes.map(({ value, label }, index) => (
                <RadioGroup.Item
                  key={index}
                  label={label}
                  value={value}
                  disabled={!event?.paymentMethod}
                />
              ))}
            </RadioGroup.Root>
          </Box> */}

          <Box display={"flex"} alignItems={"center"}>
            <Input
              label="Observações"
              disabled
              {...register("observationsEvent")}
            />
          </Box>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default BriefingAccordion;
