import {
  Box,
  Checkbox,
  Dialog,
  InputLabel,
  Switch,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Collapse,
  Fade,
} from "@mui/material";
import { GridSortDirection } from "@mui/x-data-grid";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FaCircle, FaCaretDown, FaCaretRight } from "react-icons/fa";
import { TbEyeEdit } from "react-icons/tb";
import { useQuery } from "react-query";
import { Column, CellProps } from "react-table";
import { Type } from "typescript";

import { TYPE_PERMISSIONS } from "../../../../../utils/constPermissions";
import ExpandableTableComponent from "../../../../ExpandableDataGrid";
import { options } from "../../../../SideBar2";
import { FunctionDescDiv } from "../styles";

type Props = {
  modules?: any;
  setActive: any;
  active: Array<ArrayActive> | [];
  optsState: any;
  setOpts: any;
};

interface ArrayActive {
  title: string;
  type: string;
  status: boolean;
}

interface Submodule {
  name: string;
  type: {
    create: { tmp: boolean; active: boolean };
    delete: { tmp: boolean; active: boolean };
    update: { tmp: boolean; active: boolean };
    view: { tmp: boolean; active: boolean };
  };
}

const initialOptions = [
  {
    name: "Dashboard",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
    },
    submodules: [
      {
        name: "Visão CEO",
        type: {
          create: { tmp: false, active: false },
          delete: { tmp: false, active: false },
          update: { tmp: false, active: false },
          view: { tmp: false, active: false },
        },
      },
      {
        name: "Visão Supervisores",
        type: {
          create: { tmp: false, active: false },
          delete: { tmp: false, active: false },
          update: { tmp: false, active: false },
          view: { tmp: false, active: false },
        },
      },
      {
        name: "Visão Gerentes",
        type: {
          create: { tmp: false, active: false },
          delete: { tmp: false, active: false },
          update: { tmp: false, active: false },
          view: { tmp: false, active: false },
        },
      },
    ],
  },
  {
    name: "Projetos",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
      global: { tmp: false, active: false },
    },
  },
  {
    name: "Parceiros",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
    },
  },
  {
    name: "Relatórios",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
    },
  },
  {
    name: "Controle de acesso",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
    },
  },
];

const ListModulesPermissions: React.FC<Props> = (props) => {
  const { modules, active, setActive, optsState, setOpts } = props;
  const [optionsState, setOptionsState] = useState<any>([]);
  const [originalOptionsState, setOriginalOptionsState] = useState<any>([]);

  const [page, setPage] = useState<any>({
    page: 0,
    pageSize: 10,
    order: "asc" as GridSortDirection,
    sortField: "name",
    search: "",
    isActive: undefined,
  });

  const seeAllProjects = useMemo(
    () => !!optionsState.find((obj: any) => obj?.name === "Projetos")?.type?.global?.active,
    [optionsState]
  );

  const handleChange = (
    title: string,
    action: string,
    checked: boolean,
    submoduleName?: string
  ) => {
    const newOptions = JSON.parse(JSON.stringify(optionsState));

    if (submoduleName) {
      // Handle submodule permission change
      const moduleIndex = newOptions.findIndex((obj: any) => obj.name === title);
      if (moduleIndex !== -1) {
        const submoduleIndex = newOptions[moduleIndex].submodules?.findIndex(
          (sub: any) => sub.name === submoduleName
        );
        if (submoduleIndex !== -1 && submoduleIndex !== undefined) {
          if (!newOptions[moduleIndex].submodules[submoduleIndex].type[action]) {
            newOptions[moduleIndex].submodules[submoduleIndex].type[action] = { active: checked };
          }
          newOptions[moduleIndex].submodules[submoduleIndex].type[action].active = checked;
        }
      }
    } else {
      // Handle module permission change
      const moduleIndex = newOptions.findIndex((obj: any) => obj.name === title);
      if (moduleIndex !== -1) {
        if (!newOptions[moduleIndex].type[action]) {
          newOptions[moduleIndex].type[action] = { active: checked };
        }
        newOptions[moduleIndex].type[action].active = checked;
      }
    }

    setOptionsState(newOptions);
    setOpts(newOptions);
  };

  useEffect(() => {
    if (!!modules && Array.isArray(modules[0])) {
      setOptionsState(modules[0]);
      setOpts(modules[0]);
    } else {
      setOptionsState(initialOptions);
      setOpts(initialOptions);
    }
  }, [modules]);

  // Verificar explicitamente se o Dashboard tem submódulos
  useEffect(() => {
    if (optionsState.length > 0) {
      const dashboardModule = optionsState.find((mod: any) => mod.name === "Dashboard");
      console.log("Dashboard module:", dashboardModule);
      if (
        dashboardModule &&
        (!dashboardModule.submodules || dashboardModule.submodules.length === 0)
      ) {
        // Se o Dashboard não tiver submódulos, adicione-os
        const updatedOptions = [...optionsState];
        const dashboardIndex = updatedOptions.findIndex((mod: any) => mod.name === "Dashboard");
        if (dashboardIndex !== -1) {
          updatedOptions[dashboardIndex].submodules = [
            {
              name: "Visão CEO",
              type: {
                create: { tmp: false, active: false },
                delete: { tmp: false, active: false },
                update: { tmp: false, active: false },
                view: { tmp: false, active: false },
              },
            },
            {
              name: "Visão Supervisores",
              type: {
                create: { tmp: false, active: false },
                delete: { tmp: false, active: false },
                update: { tmp: false, active: false },
                view: { tmp: false, active: false },
              },
            },
            {
              name: "Visão Gerentes",
              type: {
                create: { tmp: false, active: false },
                delete: { tmp: false, active: false },
                update: { tmp: false, active: false },
                view: { tmp: false, active: false },
              },
            },
          ];
          setOptionsState(updatedOptions);
          setOpts(updatedOptions);
        }
      }
    }
  }, [optionsState]);

  interface IModulosRow extends Type {
    _id: string;
    name: string;
    active: boolean;
    description: string;
    type: any;
    submodules?: Submodule[];
  }

  const columns: Column<IModulosRow>[] = [
    {
      Header: "Módulos",
      accessor: "name",
      Filter: "",
      Cell: ({ value, row }: CellProps<IModulosRow>) => {
        const canExpand = !!row.original.submodules?.length;

        return (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              cursor: canExpand ? "pointer" : "default",
              "&:hover": canExpand ? { color: "#1976d2" } : {},
              height: "40px",
              width: "100%",
            }}
            {...(canExpand ? row.getToggleRowExpandedProps() : {})}
          >
            <Box
              width="20px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                transition: "transform 0.3s ease",
                transform: row.isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
              }}
            >
              {canExpand && <FaCaretDown size={16} color="#1976d2" />}
            </Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 400, ml: "4px" }}>{value}</Typography>
          </Box>
        );
      },
      width: 256,
    },
    {
      Header: "Ler",
      accessor: "type",
      id: "read",
      Filter: "",
      Cell: ({ cell, row }: CellProps<IModulosRow>) => {
        return (
          <Box width={80}>
            <Checkbox
              checked={cell.value.view.active}
              onChange={(e) => handleChange(row.values.name, "view", e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
              size="small"
              sx={{ padding: 0 }}
            />
          </Box>
        );
      },
      width: 80,
    },
    {
      Header: "Editar",
      accessor: "type",
      id: "edit",
      Filter: "",
      Cell: ({ cell, row }: CellProps<IModulosRow>) => {
        return (
          <Box width={80}>
            <Checkbox
              checked={cell.value.update.active}
              onChange={(e) => handleChange(row.values.name, "update", e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
              size="small"
              sx={{ padding: 0 }}
            />
          </Box>
        );
      },
      width: 80,
    },
    {
      Header: "Criar",
      accessor: "type",
      id: "create",
      Filter: "",
      Cell: ({ cell, row }: CellProps<IModulosRow>) => {
        return (
          <Box width={80}>
            <Checkbox
              checked={cell.value.create.active}
              onChange={(e) => handleChange(row.values.name, "create", e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
              size="small"
              sx={{ padding: 0 }}
            />
          </Box>
        );
      },
      width: 80,
    },
    {
      Header: "Deletar",
      accessor: "type",
      id: "delete",
      Filter: "",
      Cell: ({ cell, row }: CellProps<IModulosRow>) => {
        return (
          <Box width={80}>
            <Checkbox
              checked={cell.value.delete.active}
              onChange={(e) => handleChange(row.values.name, "delete", e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
              size="small"
              sx={{ padding: 0 }}
            />
          </Box>
        );
      },
      width: 80,
    },
  ];

  // Função para renderizar os submodules quando uma linha estiver expandida
  const renderSubComponent = (row: any) => {
    const module = row.original;

    if (!module.submodules || module.submodules.length === 0) {
      return null;
    }

    return (
      <Fade in={true} timeout={300}>
        <div>
          <Collapse in={true} timeout={400} mountOnEnter unmountOnExit>
            <Box>
              {module.submodules.map((submodule: Submodule, index: number) => (
                <Box
                  key={index}
                  sx={{
                    height: "40px",
                    transition: "background-color 0.2s ease",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" height="40px" sx={{ width: "100%" }}>
                    <Box width={256} display="flex" alignItems="center">
                      <Box width="20px" visibility="hidden">
                        {/* Espaço ocupado pelo ícone no módulo principal */}
                      </Box>
                      <Typography sx={{ fontSize: "14px", fontWeight: 400, ml: 1 }}>
                        {submodule.name}
                      </Typography>
                    </Box>

                    <Box width={80}>
                      <Checkbox
                        checked={submodule.type.view.active}
                        onChange={(e) =>
                          handleChange(module.name, "view", e.target.checked, submodule.name)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        color="primary"
                        size="small"
                        sx={{ padding: 0 }}
                      />
                    </Box>

                    <Box width={80}>
                      <Checkbox
                        checked={submodule.type.update.active}
                        onChange={(e) =>
                          handleChange(module.name, "update", e.target.checked, submodule.name)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        color="primary"
                        size="small"
                        sx={{ padding: 0 }}
                      />
                    </Box>

                    <Box width={80}>
                      <Checkbox
                        checked={submodule.type.create.active}
                        onChange={(e) =>
                          handleChange(module.name, "create", e.target.checked, submodule.name)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        color="primary"
                        size="small"
                        sx={{ padding: 0 }}
                      />
                    </Box>

                    <Box width={80}>
                      <Checkbox
                        checked={submodule.type.delete.active}
                        onChange={(e) =>
                          handleChange(module.name, "delete", e.target.checked, submodule.name)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        color="primary"
                        size="small"
                        sx={{ padding: 0 }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Collapse>
        </div>
      </Fade>
    );
  };

  // Função para verificar se uma linha pode expandir
  const getRowCanExpand = (row: any) => {
    const module = row.original;
    return !!module.submodules && module.submodules.length > 0;
  };

  // Mensagem de depuração para verificar se Dashboard tem submodules
  console.log(
    "Modulos com submodules:",
    optionsState
      .filter((mod: any) => mod.submodules && mod.submodules.length > 0)
      .map((mod: any) => mod.name)
  );

  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mb={3} mt={2} gap={2}>
        <Box sx={{ borderBottom: 1, width: "100%" }}>
          <InputLabel>Listar módulos</InputLabel>
        </Box>

        <Box sx={{ minHeight: 200, width: "100%" }}>
          <Box display={"flex"} alignItems={"center"} marginBottom={1}>
            <InputLabel sx={{ color: "#4B465C", fontSize: 14 }}>
              Visualizar todos os projetos criados
            </InputLabel>
            <Switch
              checked={seeAllProjects}
              onChange={(e) => handleChange("Projetos", "global", e.target.checked)}
              color="primary"
            />
          </Box>
          <ExpandableTableComponent
            columns={columns}
            isLoading={false}
            data={optionsState}
            renderSubComponent={renderSubComponent}
            getRowCanExpand={getRowCanExpand}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListModulesPermissions;
