import * as React from "react";
import { Controller, Control } from "react-hook-form";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";

interface ToggleOption {
  value: string;
  label: string;
}

interface ColorToggleButtonProps {
  name: string;
  options: ToggleOption[];
  control: Control<any>;
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  textTransform: "none",
  backgroundColor: "white",
  color: "#222222",
  border: "none",
  borderRadius: 0,
  width: 100,
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  whiteSpace: "normal",
  wordBreak: "break-word",
  minHeight: "40px",
  lineHeight: "1.2",
  margin: 0,
  "&.Mui-selected": {
    backgroundColor: "#1361A4",
    color: "white",
    "&:hover": {
      backgroundColor: "#1361A4",
    },
  },
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  position: "relative",
  "&:not(:last-child)": {
    borderRight: "1px solid rgba(0, 0, 0, 0.23)",
  },
}));

export default function ColorToggleButton({
  name,
  options,
  control,
}: ColorToggleButtonProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ToggleButtonGroup
          color="primary"
          value={field.value}
          exclusive
          onChange={(_, newValue) => {
            if (newValue !== null) {
              field.onChange(newValue);
            }
          }}
          aria-label={name}
          sx={{
            width: "80%",
            display: "flex",
            border: "1px solid #ddd",
            borderRadius: "8px",
            overflow: "hidden",
            marginBottom: 0,
            maxWidth: 300,
          }}
        >
          {options.map((option) => (
            <StyledToggleButton key={option.value} value={option.value}>
              {option.label}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    />
  );
}
